var sr = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function an() {
  this.__data__ = [], this.size = 0;
}
var on = an;
function un(r, t) {
  return r === t || r !== r && t !== t;
}
var Vr = un, sn = Vr;
function cn(r, t) {
  for (var e = r.length; e--; )
    if (sn(r[e][0], t))
      return e;
  return -1;
}
var hr = cn, fn = hr, ln = Array.prototype, vn = ln.splice;
function pn(r) {
  var t = this.__data__, e = fn(t, r);
  if (e < 0)
    return !1;
  var n = t.length - 1;
  return e == n ? t.pop() : vn.call(t, e, 1), --this.size, !0;
}
var gn = pn, yn = hr;
function hn(r) {
  var t = this.__data__, e = yn(t, r);
  return e < 0 ? void 0 : t[e][1];
}
var $n = hn, dn = hr;
function _n(r) {
  return dn(this.__data__, r) > -1;
}
var bn = _n, Tn = hr;
function An(r, t) {
  var e = this.__data__, n = Tn(e, r);
  return n < 0 ? (++this.size, e.push([r, t])) : e[n][1] = t, this;
}
var mn = An, Sn = on, On = gn, wn = $n, jn = bn, Cn = mn;
function K(r) {
  var t = -1, e = r == null ? 0 : r.length;
  for (this.clear(); ++t < e; ) {
    var n = r[t];
    this.set(n[0], n[1]);
  }
}
K.prototype.clear = Sn;
K.prototype.delete = On;
K.prototype.get = wn;
K.prototype.has = jn;
K.prototype.set = Cn;
var $r = K, En = $r;
function Pn() {
  this.__data__ = new En(), this.size = 0;
}
var In = Pn;
function Ln(r) {
  var t = this.__data__, e = t.delete(r);
  return this.size = t.size, e;
}
var Rn = Ln;
function qn(r) {
  return this.__data__.get(r);
}
var Mn = qn;
function Nn(r) {
  return this.__data__.has(r);
}
var Dn = Nn, Un = typeof sr == "object" && sr && sr.Object === Object && sr, xt = Un, Gn = xt, Bn = typeof self == "object" && self && self.Object === Object && self, Fn = Gn || Bn || Function("return this")(), w = Fn, Kn = w, Hn = Kn.Symbol, dr = Hn, lt = dr, re = Object.prototype, Vn = re.hasOwnProperty, Wn = re.toString, X = lt ? lt.toStringTag : void 0;
function zn(r) {
  var t = Vn.call(r, X), e = r[X];
  try {
    r[X] = void 0;
    var n = !0;
  } catch {
  }
  var a = Wn.call(r);
  return n && (t ? r[X] = e : delete r[X]), a;
}
var Jn = zn, Xn = Object.prototype, Qn = Xn.toString;
function Yn(r) {
  return Qn.call(r);
}
var Zn = Yn, vt = dr, kn = Jn, xn = Zn, ra = "[object Null]", ta = "[object Undefined]", pt = vt ? vt.toStringTag : void 0;
function ea(r) {
  return r == null ? r === void 0 ? ta : ra : pt && pt in Object(r) ? kn(r) : xn(r);
}
var _r = ea;
function na(r) {
  var t = typeof r;
  return r != null && (t == "object" || t == "function");
}
var x = na, aa = _r, oa = x, ia = "[object AsyncFunction]", ua = "[object Function]", sa = "[object GeneratorFunction]", ca = "[object Proxy]";
function fa(r) {
  if (!oa(r))
    return !1;
  var t = aa(r);
  return t == ua || t == sa || t == ia || t == ca;
}
var te = fa, la = w, va = la["__core-js_shared__"], pa = va, Er = pa, gt = function() {
  var r = /[^.]+$/.exec(Er && Er.keys && Er.keys.IE_PROTO || "");
  return r ? "Symbol(src)_1." + r : "";
}();
function ga(r) {
  return !!gt && gt in r;
}
var ya = ga, ha = Function.prototype, $a = ha.toString;
function da(r) {
  if (r != null) {
    try {
      return $a.call(r);
    } catch {
    }
    try {
      return r + "";
    } catch {
    }
  }
  return "";
}
var ee = da, _a = te, ba = ya, Ta = x, Aa = ee, ma = /[\\^$.*+?()[\]{}|]/g, Sa = /^\[object .+?Constructor\]$/, Oa = Function.prototype, wa = Object.prototype, ja = Oa.toString, Ca = wa.hasOwnProperty, Ea = RegExp(
  "^" + ja.call(Ca).replace(ma, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Pa(r) {
  if (!Ta(r) || ba(r))
    return !1;
  var t = _a(r) ? Ea : Sa;
  return t.test(Aa(r));
}
var Ia = Pa;
function La(r, t) {
  return r == null ? void 0 : r[t];
}
var Ra = La, qa = Ia, Ma = Ra;
function Na(r, t) {
  var e = Ma(r, t);
  return qa(e) ? e : void 0;
}
var R = Na, Da = R, Ua = w, Ga = Da(Ua, "Map"), Wr = Ga, Ba = R, Fa = Ba(Object, "create"), br = Fa, yt = br;
function Ka() {
  this.__data__ = yt ? yt(null) : {}, this.size = 0;
}
var Ha = Ka;
function Va(r) {
  var t = this.has(r) && delete this.__data__[r];
  return this.size -= t ? 1 : 0, t;
}
var Wa = Va, za = br, Ja = "__lodash_hash_undefined__", Xa = Object.prototype, Qa = Xa.hasOwnProperty;
function Ya(r) {
  var t = this.__data__;
  if (za) {
    var e = t[r];
    return e === Ja ? void 0 : e;
  }
  return Qa.call(t, r) ? t[r] : void 0;
}
var Za = Ya, ka = br, xa = Object.prototype, ro = xa.hasOwnProperty;
function to(r) {
  var t = this.__data__;
  return ka ? t[r] !== void 0 : ro.call(t, r);
}
var eo = to, no = br, ao = "__lodash_hash_undefined__";
function oo(r, t) {
  var e = this.__data__;
  return this.size += this.has(r) ? 0 : 1, e[r] = no && t === void 0 ? ao : t, this;
}
var io = oo, uo = Ha, so = Wa, co = Za, fo = eo, lo = io;
function H(r) {
  var t = -1, e = r == null ? 0 : r.length;
  for (this.clear(); ++t < e; ) {
    var n = r[t];
    this.set(n[0], n[1]);
  }
}
H.prototype.clear = uo;
H.prototype.delete = so;
H.prototype.get = co;
H.prototype.has = fo;
H.prototype.set = lo;
var vo = H, ht = vo, po = $r, go = Wr;
function yo() {
  this.size = 0, this.__data__ = {
    hash: new ht(),
    map: new (go || po)(),
    string: new ht()
  };
}
var ho = yo;
function $o(r) {
  var t = typeof r;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? r !== "__proto__" : r === null;
}
var _o = $o, bo = _o;
function To(r, t) {
  var e = r.__data__;
  return bo(t) ? e[typeof t == "string" ? "string" : "hash"] : e.map;
}
var Tr = To, Ao = Tr;
function mo(r) {
  var t = Ao(this, r).delete(r);
  return this.size -= t ? 1 : 0, t;
}
var So = mo, Oo = Tr;
function wo(r) {
  return Oo(this, r).get(r);
}
var jo = wo, Co = Tr;
function Eo(r) {
  return Co(this, r).has(r);
}
var Po = Eo, Io = Tr;
function Lo(r, t) {
  var e = Io(this, r), n = e.size;
  return e.set(r, t), this.size += e.size == n ? 0 : 1, this;
}
var Ro = Lo, qo = ho, Mo = So, No = jo, Do = Po, Uo = Ro;
function V(r) {
  var t = -1, e = r == null ? 0 : r.length;
  for (this.clear(); ++t < e; ) {
    var n = r[t];
    this.set(n[0], n[1]);
  }
}
V.prototype.clear = qo;
V.prototype.delete = Mo;
V.prototype.get = No;
V.prototype.has = Do;
V.prototype.set = Uo;
var ne = V, Go = $r, Bo = Wr, Fo = ne, Ko = 200;
function Ho(r, t) {
  var e = this.__data__;
  if (e instanceof Go) {
    var n = e.__data__;
    if (!Bo || n.length < Ko - 1)
      return n.push([r, t]), this.size = ++e.size, this;
    e = this.__data__ = new Fo(n);
  }
  return e.set(r, t), this.size = e.size, this;
}
var Vo = Ho, Wo = $r, zo = In, Jo = Rn, Xo = Mn, Qo = Dn, Yo = Vo;
function W(r) {
  var t = this.__data__ = new Wo(r);
  this.size = t.size;
}
W.prototype.clear = zo;
W.prototype.delete = Jo;
W.prototype.get = Xo;
W.prototype.has = Qo;
W.prototype.set = Yo;
var ae = W;
function Zo(r, t) {
  for (var e = -1, n = r == null ? 0 : r.length; ++e < n && t(r[e], e, r) !== !1; )
    ;
  return r;
}
var ko = Zo, xo = R, ri = function() {
  try {
    var r = xo(Object, "defineProperty");
    return r({}, "", {}), r;
  } catch {
  }
}(), ti = ri, $t = ti;
function ei(r, t, e) {
  t == "__proto__" && $t ? $t(r, t, {
    configurable: !0,
    enumerable: !0,
    value: e,
    writable: !0
  }) : r[t] = e;
}
var oe = ei, ni = oe, ai = Vr, oi = Object.prototype, ii = oi.hasOwnProperty;
function ui(r, t, e) {
  var n = r[t];
  (!(ii.call(r, t) && ai(n, e)) || e === void 0 && !(t in r)) && ni(r, t, e);
}
var ie = ui, si = ie, ci = oe;
function fi(r, t, e, n) {
  var a = !e;
  e || (e = {});
  for (var o = -1, i = t.length; ++o < i; ) {
    var u = t[o], s = n ? n(e[u], r[u], u, e, r) : void 0;
    s === void 0 && (s = r[u]), a ? ci(e, u, s) : si(e, u, s);
  }
  return e;
}
var Ar = fi;
function li(r, t) {
  for (var e = -1, n = Array(r); ++e < r; )
    n[e] = t(e);
  return n;
}
var vi = li;
function pi(r) {
  return r != null && typeof r == "object";
}
var z = pi, gi = _r, yi = z, hi = "[object Arguments]";
function $i(r) {
  return yi(r) && gi(r) == hi;
}
var di = $i, dt = di, _i = z, ue = Object.prototype, bi = ue.hasOwnProperty, Ti = ue.propertyIsEnumerable, Ai = dt(function() {
  return arguments;
}()) ? dt : function(r) {
  return _i(r) && bi.call(r, "callee") && !Ti.call(r, "callee");
}, mi = Ai, Si = Array.isArray, mr = Si, Q = { exports: {} };
function Oi() {
  return !1;
}
var wi = Oi;
(function(r, t) {
  var e = w, n = wi, a = t && !t.nodeType && t, o = a && !0 && r && !r.nodeType && r, i = o && o.exports === a, u = i ? e.Buffer : void 0, s = u ? u.isBuffer : void 0, c = s || n;
  r.exports = c;
})(Q, Q.exports);
var ji = 9007199254740991, Ci = /^(?:0|[1-9]\d*)$/;
function Ei(r, t) {
  var e = typeof r;
  return t = t == null ? ji : t, !!t && (e == "number" || e != "symbol" && Ci.test(r)) && r > -1 && r % 1 == 0 && r < t;
}
var Pi = Ei, Ii = 9007199254740991;
function Li(r) {
  return typeof r == "number" && r > -1 && r % 1 == 0 && r <= Ii;
}
var se = Li, Ri = _r, qi = se, Mi = z, Ni = "[object Arguments]", Di = "[object Array]", Ui = "[object Boolean]", Gi = "[object Date]", Bi = "[object Error]", Fi = "[object Function]", Ki = "[object Map]", Hi = "[object Number]", Vi = "[object Object]", Wi = "[object RegExp]", zi = "[object Set]", Ji = "[object String]", Xi = "[object WeakMap]", Qi = "[object ArrayBuffer]", Yi = "[object DataView]", Zi = "[object Float32Array]", ki = "[object Float64Array]", xi = "[object Int8Array]", ru = "[object Int16Array]", tu = "[object Int32Array]", eu = "[object Uint8Array]", nu = "[object Uint8ClampedArray]", au = "[object Uint16Array]", ou = "[object Uint32Array]", g = {};
g[Zi] = g[ki] = g[xi] = g[ru] = g[tu] = g[eu] = g[nu] = g[au] = g[ou] = !0;
g[Ni] = g[Di] = g[Qi] = g[Ui] = g[Yi] = g[Gi] = g[Bi] = g[Fi] = g[Ki] = g[Hi] = g[Vi] = g[Wi] = g[zi] = g[Ji] = g[Xi] = !1;
function iu(r) {
  return Mi(r) && qi(r.length) && !!g[Ri(r)];
}
var uu = iu;
function su(r) {
  return function(t) {
    return r(t);
  };
}
var zr = su, Y = { exports: {} };
(function(r, t) {
  var e = xt, n = t && !t.nodeType && t, a = n && !0 && r && !r.nodeType && r, o = a && a.exports === n, i = o && e.process, u = function() {
    try {
      var s = a && a.require && a.require("util").types;
      return s || i && i.binding && i.binding("util");
    } catch {
    }
  }();
  r.exports = u;
})(Y, Y.exports);
var cu = uu, fu = zr, _t = Y.exports, bt = _t && _t.isTypedArray, lu = bt ? fu(bt) : cu, ce = lu, vu = vi, pu = mi, gu = mr, yu = Q.exports, hu = Pi, $u = ce, du = Object.prototype, _u = du.hasOwnProperty;
function bu(r, t) {
  var e = gu(r), n = !e && pu(r), a = !e && !n && yu(r), o = !e && !n && !a && $u(r), i = e || n || a || o, u = i ? vu(r.length, String) : [], s = u.length;
  for (var c in r)
    (t || _u.call(r, c)) && !(i && (c == "length" || a && (c == "offset" || c == "parent") || o && (c == "buffer" || c == "byteLength" || c == "byteOffset") || hu(c, s))) && u.push(c);
  return u;
}
var fe = bu, Tu = Object.prototype;
function Au(r) {
  var t = r && r.constructor, e = typeof t == "function" && t.prototype || Tu;
  return r === e;
}
var Jr = Au;
function mu(r, t) {
  return function(e) {
    return r(t(e));
  };
}
var le = mu, Su = le, Ou = Su(Object.keys, Object), wu = Ou, ju = Jr, Cu = wu, Eu = Object.prototype, Pu = Eu.hasOwnProperty;
function Iu(r) {
  if (!ju(r))
    return Cu(r);
  var t = [];
  for (var e in Object(r))
    Pu.call(r, e) && e != "constructor" && t.push(e);
  return t;
}
var Lu = Iu, Ru = te, qu = se;
function Mu(r) {
  return r != null && qu(r.length) && !Ru(r);
}
var ve = Mu, Nu = fe, Du = Lu, Uu = ve;
function Gu(r) {
  return Uu(r) ? Nu(r) : Du(r);
}
var Xr = Gu, Bu = Ar, Fu = Xr;
function Ku(r, t) {
  return r && Bu(t, Fu(t), r);
}
var Hu = Ku;
function Vu(r) {
  var t = [];
  if (r != null)
    for (var e in Object(r))
      t.push(e);
  return t;
}
var Wu = Vu, zu = x, Ju = Jr, Xu = Wu, Qu = Object.prototype, Yu = Qu.hasOwnProperty;
function Zu(r) {
  if (!zu(r))
    return Xu(r);
  var t = Ju(r), e = [];
  for (var n in r)
    n == "constructor" && (t || !Yu.call(r, n)) || e.push(n);
  return e;
}
var ku = Zu, xu = fe, rs = ku, ts = ve;
function es(r) {
  return ts(r) ? xu(r, !0) : rs(r);
}
var Qr = es, ns = Ar, as = Qr;
function os(r, t) {
  return r && ns(t, as(t), r);
}
var is = os, Rr = { exports: {} };
(function(r, t) {
  var e = w, n = t && !t.nodeType && t, a = n && !0 && r && !r.nodeType && r, o = a && a.exports === n, i = o ? e.Buffer : void 0, u = i ? i.allocUnsafe : void 0;
  function s(c, y) {
    if (y)
      return c.slice();
    var l = c.length, f = u ? u(l) : new c.constructor(l);
    return c.copy(f), f;
  }
  r.exports = s;
})(Rr, Rr.exports);
function us(r, t) {
  var e = -1, n = r.length;
  for (t || (t = Array(n)); ++e < n; )
    t[e] = r[e];
  return t;
}
var ss = us;
function cs(r, t) {
  for (var e = -1, n = r == null ? 0 : r.length, a = 0, o = []; ++e < n; ) {
    var i = r[e];
    t(i, e, r) && (o[a++] = i);
  }
  return o;
}
var fs = cs;
function ls() {
  return [];
}
var pe = ls, vs = fs, ps = pe, gs = Object.prototype, ys = gs.propertyIsEnumerable, Tt = Object.getOwnPropertySymbols, hs = Tt ? function(r) {
  return r == null ? [] : (r = Object(r), vs(Tt(r), function(t) {
    return ys.call(r, t);
  }));
} : ps, Yr = hs, $s = Ar, ds = Yr;
function _s(r, t) {
  return $s(r, ds(r), t);
}
var bs = _s;
function Ts(r, t) {
  for (var e = -1, n = t.length, a = r.length; ++e < n; )
    r[a + e] = t[e];
  return r;
}
var ge = Ts, As = le, ms = As(Object.getPrototypeOf, Object), ye = ms, Ss = ge, Os = ye, ws = Yr, js = pe, Cs = Object.getOwnPropertySymbols, Es = Cs ? function(r) {
  for (var t = []; r; )
    Ss(t, ws(r)), r = Os(r);
  return t;
} : js, he = Es, Ps = Ar, Is = he;
function Ls(r, t) {
  return Ps(r, Is(r), t);
}
var Rs = Ls, qs = ge, Ms = mr;
function Ns(r, t, e) {
  var n = t(r);
  return Ms(r) ? n : qs(n, e(r));
}
var $e = Ns, Ds = $e, Us = Yr, Gs = Xr;
function Bs(r) {
  return Ds(r, Gs, Us);
}
var de = Bs, Fs = $e, Ks = he, Hs = Qr;
function Vs(r) {
  return Fs(r, Hs, Ks);
}
var Ws = Vs, zs = R, Js = w, Xs = zs(Js, "DataView"), Qs = Xs, Ys = R, Zs = w, ks = Ys(Zs, "Promise"), xs = ks, rc = R, tc = w, ec = rc(tc, "Set"), nc = ec, ac = R, oc = w, ic = ac(oc, "WeakMap"), uc = ic, qr = Qs, Mr = Wr, Nr = xs, Dr = nc, Ur = uc, _e = _r, J = ee, At = "[object Map]", sc = "[object Object]", mt = "[object Promise]", St = "[object Set]", Ot = "[object WeakMap]", wt = "[object DataView]", cc = J(qr), fc = J(Mr), lc = J(Nr), vc = J(Dr), pc = J(Ur), L = _e;
(qr && L(new qr(new ArrayBuffer(1))) != wt || Mr && L(new Mr()) != At || Nr && L(Nr.resolve()) != mt || Dr && L(new Dr()) != St || Ur && L(new Ur()) != Ot) && (L = function(r) {
  var t = _e(r), e = t == sc ? r.constructor : void 0, n = e ? J(e) : "";
  if (n)
    switch (n) {
      case cc:
        return wt;
      case fc:
        return At;
      case lc:
        return mt;
      case vc:
        return St;
      case pc:
        return Ot;
    }
  return t;
});
var Sr = L, gc = Object.prototype, yc = gc.hasOwnProperty;
function hc(r) {
  var t = r.length, e = new r.constructor(t);
  return t && typeof r[0] == "string" && yc.call(r, "index") && (e.index = r.index, e.input = r.input), e;
}
var $c = hc, dc = w, _c = dc.Uint8Array, be = _c, jt = be;
function bc(r) {
  var t = new r.constructor(r.byteLength);
  return new jt(t).set(new jt(r)), t;
}
var Zr = bc, Tc = Zr;
function Ac(r, t) {
  var e = t ? Tc(r.buffer) : r.buffer;
  return new r.constructor(e, r.byteOffset, r.byteLength);
}
var mc = Ac, Sc = /\w*$/;
function Oc(r) {
  var t = new r.constructor(r.source, Sc.exec(r));
  return t.lastIndex = r.lastIndex, t;
}
var wc = Oc, Ct = dr, Et = Ct ? Ct.prototype : void 0, Pt = Et ? Et.valueOf : void 0;
function jc(r) {
  return Pt ? Object(Pt.call(r)) : {};
}
var Cc = jc, Ec = Zr;
function Pc(r, t) {
  var e = t ? Ec(r.buffer) : r.buffer;
  return new r.constructor(e, r.byteOffset, r.length);
}
var Ic = Pc, Lc = Zr, Rc = mc, qc = wc, Mc = Cc, Nc = Ic, Dc = "[object Boolean]", Uc = "[object Date]", Gc = "[object Map]", Bc = "[object Number]", Fc = "[object RegExp]", Kc = "[object Set]", Hc = "[object String]", Vc = "[object Symbol]", Wc = "[object ArrayBuffer]", zc = "[object DataView]", Jc = "[object Float32Array]", Xc = "[object Float64Array]", Qc = "[object Int8Array]", Yc = "[object Int16Array]", Zc = "[object Int32Array]", kc = "[object Uint8Array]", xc = "[object Uint8ClampedArray]", rf = "[object Uint16Array]", tf = "[object Uint32Array]";
function ef(r, t, e) {
  var n = r.constructor;
  switch (t) {
    case Wc:
      return Lc(r);
    case Dc:
    case Uc:
      return new n(+r);
    case zc:
      return Rc(r, e);
    case Jc:
    case Xc:
    case Qc:
    case Yc:
    case Zc:
    case kc:
    case xc:
    case rf:
    case tf:
      return Nc(r, e);
    case Gc:
      return new n();
    case Bc:
    case Hc:
      return new n(r);
    case Fc:
      return qc(r);
    case Kc:
      return new n();
    case Vc:
      return Mc(r);
  }
}
var nf = ef, af = x, It = Object.create, of = function() {
  function r() {
  }
  return function(t) {
    if (!af(t))
      return {};
    if (It)
      return It(t);
    r.prototype = t;
    var e = new r();
    return r.prototype = void 0, e;
  };
}(), uf = of, sf = uf, cf = ye, ff = Jr;
function lf(r) {
  return typeof r.constructor == "function" && !ff(r) ? sf(cf(r)) : {};
}
var vf = lf, pf = Sr, gf = z, yf = "[object Map]";
function hf(r) {
  return gf(r) && pf(r) == yf;
}
var $f = hf, df = $f, _f = zr, Lt = Y.exports, Rt = Lt && Lt.isMap, bf = Rt ? _f(Rt) : df, Tf = bf, Af = Sr, mf = z, Sf = "[object Set]";
function Of(r) {
  return mf(r) && Af(r) == Sf;
}
var wf = Of, jf = wf, Cf = zr, qt = Y.exports, Mt = qt && qt.isSet, Ef = Mt ? Cf(Mt) : jf, Pf = Ef, If = ae, Lf = ko, Rf = ie, qf = Hu, Mf = is, Nf = Rr.exports, Df = ss, Uf = bs, Gf = Rs, Bf = de, Ff = Ws, Kf = Sr, Hf = $c, Vf = nf, Wf = vf, zf = mr, Jf = Q.exports, Xf = Tf, Qf = x, Yf = Pf, Zf = Xr, kf = Qr, xf = 1, rl = 2, tl = 4, Te = "[object Arguments]", el = "[object Array]", nl = "[object Boolean]", al = "[object Date]", ol = "[object Error]", Ae = "[object Function]", il = "[object GeneratorFunction]", ul = "[object Map]", sl = "[object Number]", me = "[object Object]", cl = "[object RegExp]", fl = "[object Set]", ll = "[object String]", vl = "[object Symbol]", pl = "[object WeakMap]", gl = "[object ArrayBuffer]", yl = "[object DataView]", hl = "[object Float32Array]", $l = "[object Float64Array]", dl = "[object Int8Array]", _l = "[object Int16Array]", bl = "[object Int32Array]", Tl = "[object Uint8Array]", Al = "[object Uint8ClampedArray]", ml = "[object Uint16Array]", Sl = "[object Uint32Array]", p = {};
p[Te] = p[el] = p[gl] = p[yl] = p[nl] = p[al] = p[hl] = p[$l] = p[dl] = p[_l] = p[bl] = p[ul] = p[sl] = p[me] = p[cl] = p[fl] = p[ll] = p[vl] = p[Tl] = p[Al] = p[ml] = p[Sl] = !0;
p[ol] = p[Ae] = p[pl] = !1;
function lr(r, t, e, n, a, o) {
  var i, u = t & xf, s = t & rl, c = t & tl;
  if (e && (i = a ? e(r, n, a, o) : e(r)), i !== void 0)
    return i;
  if (!Qf(r))
    return r;
  var y = zf(r);
  if (y) {
    if (i = Hf(r), !u)
      return Df(r, i);
  } else {
    var l = Kf(r), f = l == Ae || l == il;
    if (Jf(r))
      return Nf(r, u);
    if (l == me || l == Te || f && !a) {
      if (i = s || f ? {} : Wf(r), !u)
        return s ? Gf(r, Mf(i, r)) : Uf(r, qf(i, r));
    } else {
      if (!p[l])
        return a ? r : {};
      i = Vf(r, l, u);
    }
  }
  o || (o = new If());
  var S = o.get(r);
  if (S)
    return S;
  o.set(r, i), Yf(r) ? r.forEach(function($) {
    i.add(lr($, t, e, $, r, o));
  }) : Xf(r) && r.forEach(function($, T) {
    i.set(T, lr($, t, e, T, r, o));
  });
  var A = c ? s ? Ff : Bf : s ? kf : Zf, m = y ? void 0 : A(r);
  return Lf(m || r, function($, T) {
    m && (T = $, $ = r[T]), Rf(i, T, lr($, t, e, T, r, o));
  }), i;
}
var Ol = lr, wl = Ol, jl = 1, Cl = 4;
function El(r) {
  return wl(r, jl | Cl);
}
var Pl = El;
function Il(r) {
  return r;
}
function E(r, t, e, n, a, o, i, u, s) {
  switch (arguments.length) {
    case 1:
      return r;
    case 2:
      return function() {
        return t(r.apply(this, arguments));
      };
    case 3:
      return function() {
        return e(t(r.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(e(t(r.apply(this, arguments))));
      };
    case 5:
      return function() {
        return a(n(e(t(r.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return o(a(n(e(t(r.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return i(o(a(n(e(t(r.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return u(i(o(a(n(e(t(r.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return s(u(i(o(a(n(e(t(r.apply(this, arguments)))))))));
      };
  }
}
function h(r, t, e, n, a, o, i, u, s) {
  switch (arguments.length) {
    case 1:
      return r;
    case 2:
      return t(r);
    case 3:
      return e(t(r));
    case 4:
      return n(e(t(r)));
    case 5:
      return a(n(e(t(r))));
    case 6:
      return o(a(n(e(t(r)))));
    case 7:
      return i(o(a(n(e(t(r))))));
    case 8:
      return u(i(o(a(n(e(t(r)))))));
    case 9:
      return s(u(i(o(a(n(e(t(r))))))));
    default: {
      for (var c = arguments[0], y = 1; y < arguments.length; y++)
        c = arguments[y](c);
      return c;
    }
  }
}
function q(r) {
  return function(t) {
    return !r(t);
  };
}
var Ll = function(r) {
  return {
    equals: function(t, e) {
      return t === e || r(t, e);
    }
  };
}, Rl = function(r) {
  return Ll(function(t, e) {
    for (var n in r)
      if (!r[n].equals(t[n], e[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var Se = function(r) {
  return r._tag === "Some";
}, Oe = { _tag: "None" }, we = function(r) {
  return { _tag: "Some", value: r };
}, ql = function(r) {
  return r._tag === "Left";
}, Ml = function(r) {
  return r._tag === "Right";
}, Nl = function(r) {
  return { _tag: "Left", left: r };
}, Dl = function(r) {
  return { _tag: "Right", right: r };
}, Ul = function(r) {
  return [r];
}, Gl = [], Bl = Object.prototype.hasOwnProperty, Fl = function(r) {
  return function(t) {
    return function(e) {
      return e.reduce(function(n, a) {
        return r.concat(n, a);
      }, t);
    };
  };
}, Kl = function(r) {
  return {
    concat: function() {
      return r;
    }
  };
}, Hl = function(r) {
  return {
    concat: function(t, e) {
      var n = {};
      for (var a in r)
        Bl.call(r, a) && (n[a] = r[a].concat(t[a], e[a]));
      return n;
    }
  };
}, Vl = function() {
  return { concat: Il };
}, Wl = function() {
  return { concat: function(r, t) {
    return t;
  } };
}, zl = Fl, Jl = Kl(void 0), Xl = Wl, Pr = Vl, Ql = Hl, Yl = {
  concat: function(r, t) {
    return r + t;
  }
};
globalThis && globalThis.__spreadArray;
var Zl = Gl, kl = function(r, t) {
  return r < 0 || r >= t.length;
};
function xl(r, t) {
  return t ? r.concat(t) : function(e) {
    return e.concat(r);
  };
}
var rv = Ul, tv = function(r, t) {
  return h(r, xl([t]));
}, C = {
  equals: function(r, t) {
    return r === t;
  }
};
C.equals;
var je = function(r) {
  return r.trim();
}, Ce = function(r) {
  return r.length === 0;
}, ev = "__lodash_hash_undefined__";
function nv(r) {
  return this.__data__.set(r, ev), this;
}
var av = nv;
function ov(r) {
  return this.__data__.has(r);
}
var iv = ov, uv = ne, sv = av, cv = iv;
function vr(r) {
  var t = -1, e = r == null ? 0 : r.length;
  for (this.__data__ = new uv(); ++t < e; )
    this.add(r[t]);
}
vr.prototype.add = vr.prototype.push = sv;
vr.prototype.has = cv;
var fv = vr;
function lv(r, t) {
  for (var e = -1, n = r == null ? 0 : r.length; ++e < n; )
    if (t(r[e], e, r))
      return !0;
  return !1;
}
var vv = lv;
function pv(r, t) {
  return r.has(t);
}
var gv = pv, yv = fv, hv = vv, $v = gv, dv = 1, _v = 2;
function bv(r, t, e, n, a, o) {
  var i = e & dv, u = r.length, s = t.length;
  if (u != s && !(i && s > u))
    return !1;
  var c = o.get(r), y = o.get(t);
  if (c && y)
    return c == t && y == r;
  var l = -1, f = !0, S = e & _v ? new yv() : void 0;
  for (o.set(r, t), o.set(t, r); ++l < u; ) {
    var A = r[l], m = t[l];
    if (n)
      var $ = i ? n(m, A, l, t, r, o) : n(A, m, l, r, t, o);
    if ($ !== void 0) {
      if ($)
        continue;
      f = !1;
      break;
    }
    if (S) {
      if (!hv(t, function(T, j) {
        if (!$v(S, j) && (A === T || a(A, T, e, n, o)))
          return S.push(j);
      })) {
        f = !1;
        break;
      }
    } else if (!(A === m || a(A, m, e, n, o))) {
      f = !1;
      break;
    }
  }
  return o.delete(r), o.delete(t), f;
}
var Ee = bv;
function Tv(r) {
  var t = -1, e = Array(r.size);
  return r.forEach(function(n, a) {
    e[++t] = [a, n];
  }), e;
}
var Av = Tv;
function mv(r) {
  var t = -1, e = Array(r.size);
  return r.forEach(function(n) {
    e[++t] = n;
  }), e;
}
var Sv = mv, Nt = dr, Dt = be, Ov = Vr, wv = Ee, jv = Av, Cv = Sv, Ev = 1, Pv = 2, Iv = "[object Boolean]", Lv = "[object Date]", Rv = "[object Error]", qv = "[object Map]", Mv = "[object Number]", Nv = "[object RegExp]", Dv = "[object Set]", Uv = "[object String]", Gv = "[object Symbol]", Bv = "[object ArrayBuffer]", Fv = "[object DataView]", Ut = Nt ? Nt.prototype : void 0, Ir = Ut ? Ut.valueOf : void 0;
function Kv(r, t, e, n, a, o, i) {
  switch (e) {
    case Fv:
      if (r.byteLength != t.byteLength || r.byteOffset != t.byteOffset)
        return !1;
      r = r.buffer, t = t.buffer;
    case Bv:
      return !(r.byteLength != t.byteLength || !o(new Dt(r), new Dt(t)));
    case Iv:
    case Lv:
    case Mv:
      return Ov(+r, +t);
    case Rv:
      return r.name == t.name && r.message == t.message;
    case Nv:
    case Uv:
      return r == t + "";
    case qv:
      var u = jv;
    case Dv:
      var s = n & Ev;
      if (u || (u = Cv), r.size != t.size && !s)
        return !1;
      var c = i.get(r);
      if (c)
        return c == t;
      n |= Pv, i.set(r, t);
      var y = wv(u(r), u(t), n, a, o, i);
      return i.delete(r), y;
    case Gv:
      if (Ir)
        return Ir.call(r) == Ir.call(t);
  }
  return !1;
}
var Hv = Kv, Gt = de, Vv = 1, Wv = Object.prototype, zv = Wv.hasOwnProperty;
function Jv(r, t, e, n, a, o) {
  var i = e & Vv, u = Gt(r), s = u.length, c = Gt(t), y = c.length;
  if (s != y && !i)
    return !1;
  for (var l = s; l--; ) {
    var f = u[l];
    if (!(i ? f in t : zv.call(t, f)))
      return !1;
  }
  var S = o.get(r), A = o.get(t);
  if (S && A)
    return S == t && A == r;
  var m = !0;
  o.set(r, t), o.set(t, r);
  for (var $ = i; ++l < s; ) {
    f = u[l];
    var T = r[f], j = t[f];
    if (n)
      var ur = i ? n(j, T, f, t, r, o) : n(T, j, f, r, t, o);
    if (!(ur === void 0 ? T === j || a(T, j, e, n, o) : ur)) {
      m = !1;
      break;
    }
    $ || ($ = f == "constructor");
  }
  if (m && !$) {
    var I = r.constructor, D = t.constructor;
    I != D && "constructor" in r && "constructor" in t && !(typeof I == "function" && I instanceof I && typeof D == "function" && D instanceof D) && (m = !1);
  }
  return o.delete(r), o.delete(t), m;
}
var Xv = Jv, Lr = ae, Qv = Ee, Yv = Hv, Zv = Xv, Bt = Sr, Ft = mr, Kt = Q.exports, kv = ce, xv = 1, Ht = "[object Arguments]", Vt = "[object Array]", cr = "[object Object]", rp = Object.prototype, Wt = rp.hasOwnProperty;
function tp(r, t, e, n, a, o) {
  var i = Ft(r), u = Ft(t), s = i ? Vt : Bt(r), c = u ? Vt : Bt(t);
  s = s == Ht ? cr : s, c = c == Ht ? cr : c;
  var y = s == cr, l = c == cr, f = s == c;
  if (f && Kt(r)) {
    if (!Kt(t))
      return !1;
    i = !0, y = !1;
  }
  if (f && !y)
    return o || (o = new Lr()), i || kv(r) ? Qv(r, t, e, n, a, o) : Yv(r, t, s, e, n, a, o);
  if (!(e & xv)) {
    var S = y && Wt.call(r, "__wrapped__"), A = l && Wt.call(t, "__wrapped__");
    if (S || A) {
      var m = S ? r.value() : r, $ = A ? t.value() : t;
      return o || (o = new Lr()), a(m, $, e, n, o);
    }
  }
  return f ? (o || (o = new Lr()), Zv(r, t, e, n, a, o)) : !1;
}
var ep = tp, np = ep, zt = z;
function Pe(r, t, e, n, a) {
  return r === t ? !0 : r == null || t == null || !zt(r) && !zt(t) ? r !== r && t !== t : np(r, t, e, n, Pe, a);
}
var ap = Pe, op = ap;
function ip(r, t) {
  return op(r, t);
}
var up = ip;
const Jt = (r) => ({
  equals(t, e) {
    return t !== void 0 && e !== void 0 ? r.equals(t, e) : t === void 0 && e === void 0;
  }
}), Xt = (r, t) => ({
  equals(e, n) {
    return t.equals(r(e), r(n));
  }
}), fr = {
  equals(r, t) {
    return up(r, t);
  }
}, Cg = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, Ie = "1", sp = Rl({
  id: Jt(C),
  v: C,
  auth: fr,
  body: fr,
  endpoint: C,
  headers: Xt(
    (r) => r.filter((t) => t.key !== "" && t.value !== ""),
    fr
  ),
  params: Xt(
    (r) => r.filter((t) => t.key !== "" && t.value !== ""),
    fr
  ),
  method: C,
  name: C,
  preRequestScript: C,
  testScript: C,
  description: Jt(C)
}), Eg = sp.equals;
function Pg(r, t) {
  const e = Pl(t);
  return !!r && typeof r == "object" && (r.hasOwnProperty("v") && typeof r.v == "string" && (e.v = r.v), r.hasOwnProperty("id") && typeof r.id == "string" && (e.id = r.id), r.hasOwnProperty("name") && typeof r.name == "string" && (e.name = r.name), r.hasOwnProperty("method") && typeof r.method == "string" && (e.method = r.method), r.hasOwnProperty("endpoint") && typeof r.endpoint == "string" && (e.endpoint = r.endpoint), r.hasOwnProperty("preRequestScript") && typeof r.preRequestScript == "string" && (e.preRequestScript = r.preRequestScript), r.hasOwnProperty("testScript") && typeof r.testScript == "string" && (e.testScript = r.testScript), r.hasOwnProperty("description") && typeof r.description == "string" && (e.description = r.description), r.hasOwnProperty("body") && typeof r.body == "object" && !!r.body && (e.body = r.body), r.hasOwnProperty("auth") && typeof r.auth == "object" && !!r.auth && (e.auth = r.auth), r.hasOwnProperty("params") && Array.isArray(r.params) && (e.params = r.params), r.hasOwnProperty("headers") && Array.isArray(r.headers) && (e.headers = r.headers), r.hasOwnProperty("description") && typeof r.description == "string" && (e.description = r.description)), e;
}
function Ig(r) {
  return {
    ...r,
    v: Ie
  };
}
function cp(r) {
  return r && typeof r == "object" && "v" in r;
}
function fp(r) {
  return r.contentType === "application/json" ? {
    contentType: "application/json",
    body: r.rawParams
  } : {
    contentType: "application/json",
    body: ""
  };
}
function lp(r) {
  var t, e, n, a, o, i, u, s, c;
  if (cp(r))
    return r;
  {
    const y = `${(t = r == null ? void 0 : r.url) != null ? t : ""}${(e = r == null ? void 0 : r.path) != null ? e : ""}`, l = (n = r == null ? void 0 : r.headers) != null ? n : [], f = ((a = r == null ? void 0 : r.params) != null ? a : []).map(
      ({
        key: D,
        value: en,
        active: nn
      }) => ({
        key: D,
        value: en,
        active: nn
      })
    ), S = (o = r == null ? void 0 : r.name) != null ? o : "Untitled request", A = (i = r == null ? void 0 : r.method) != null ? i : "", m = (u = r == null ? void 0 : r.preRequestScript) != null ? u : "", $ = (s = r == null ? void 0 : r.testScript) != null ? s : "", T = (c = r == null ? void 0 : r.description) != null ? c : void 0, j = fp(r), ur = vp(r), I = {
      name: S,
      endpoint: y,
      headers: l,
      params: f,
      method: A,
      preRequestScript: m,
      testScript: $,
      body: j,
      auth: ur,
      description: T,
      v: Ie
    };
    return r.id && (I.id = r.id), I;
  }
}
function vp(r) {
  return !r.auth || r.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : r.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: r.httpUser,
    password: r.httpPassword
  } : r.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: r.bearerToken
  } : { authType: "none", authActive: !0 };
}
const pr = 2;
function pp(r) {
  var u, s, c, y, l, f;
  if (r.v && r.v === pr)
    return r;
  const t = (u = r.name) != null ? u : "Untitled", e = (s = r.url) != null ? s : "", n = (c = r.headers) != null ? c : [], a = (y = r.query) != null ? y : "", o = (l = r.variables) != null ? l : [], i = (f = r.auth) != null ? f : {
    authType: "none",
    authActive: !0
  };
  return {
    v: pr,
    name: t,
    url: e,
    headers: n,
    query: a,
    variables: o,
    auth: i
  };
}
function Lg(r) {
  return {
    v: pr,
    ...r
  };
}
const gp = 1;
function Le(r) {
  return {
    v: gp,
    ...r
  };
}
function yp(r) {
  var o, i, u;
  if (r.v && r.v === 1)
    return r;
  const t = (o = r.name) != null ? o : "Untitled", e = ((i = r.folders) != null ? i : []).map(yp), n = ((u = r.requests) != null ? u : []).map(lp), a = Le({
    name: t,
    folders: e,
    requests: n
  });
  return r.id && (a.id = r.id), a;
}
function hp(r) {
  var o, i, u;
  if (r.v && r.v === pr)
    return r;
  const t = (o = r.name) != null ? o : "Untitled", e = ((i = r.folders) != null ? i : []).map(hp), n = ((u = r.requests) != null ? u : []).map(pp), a = Le({
    name: t,
    folders: e,
    requests: n
  });
  return r.id && (a.id = r.id), a;
}
var $p = function(r) {
  return function(t) {
    return !r(t);
  };
};
globalThis && globalThis.__spreadArray;
var dp = kl;
function Re(r, t) {
  return t === void 0 ? function(e) {
    return Re(r, e);
  } : dp(r, t) ? Oe : we(t[r]);
}
var qe = function(r) {
  return function(t) {
    return t.map(function(e) {
      return r(e);
    });
  };
}, _p = function(r) {
  return function(t) {
    for (var e = [], n = 0; n < t.length; n++) {
      var a = r(n, t[n]);
      Se(a) && e.push(a.value);
    }
    return e;
  };
}, bp = function(r) {
  return _p(function(t, e) {
    return r(e);
  });
}, Me = function(r) {
  return r.slice();
}, Tp = Zl, Qt = globalThis && globalThis.__spreadArray || function(r, t, e) {
  if (e || arguments.length === 2)
    for (var n = 0, a = t.length, o; n < a; n++)
      (o || !(n in t)) && (o || (o = Array.prototype.slice.call(t, 0, n)), o[n] = t[n]);
  return r.concat(o || Array.prototype.slice.call(t));
}, Ap = function(r) {
  return function(t) {
    return Qt(Qt([], t, !0), [r], !1);
  };
}, mp = Ap, Sp = function(r) {
  return [r];
}, Op = function(r, t) {
  return h(r, mp(t));
}, wp = Re, jp = function(r) {
  return function(t) {
    return t.map(function(e) {
      return r(e);
    });
  };
}, Cp = function() {
  return {
    concat: function(r, t) {
      return r.concat(t);
    }
  };
}, Ep = function() {
  return {
    concat: Cp().concat,
    empty: []
  };
}, Ne = [], Or = Oe, rr = we;
function De(r) {
  return function(t) {
    return r(t) ? rr(t) : Or;
  };
}
var Gr = function(r) {
  return function(t) {
    return Ue(t) ? Or : rr(r(t.value));
  };
}, Pp = Se, Ue = function(r) {
  return r._tag === "None";
}, Ip = function(r, t) {
  return function(e) {
    return Ue(e) ? r() : t(e.value);
  };
}, Lp = Ip, kr = Lp, Rp = function(r, t) {
  for (var e = t(r); e._tag === "Left"; )
    e = t(e.left);
  return e.right;
}, P = Nl, O = Dl, tr = function(r) {
  return function(t) {
    return U(t) ? t : O(r(t.right));
  };
}, qp = function(r) {
  return function(t) {
    return U(t) ? t : r(t.right);
  };
}, Yt = qp, wr = function(r) {
  return function(t) {
    return U(t) ? P(r(t.left)) : t;
  };
}, U = ql, Mp = Ml, Np = function(r) {
  return function(t) {
    return U(t) ? r(t.left) : t.right;
  };
}, xr = Np, v = h, Br = globalThis && globalThis.__assign || function() {
  return Br = Object.assign || function(r) {
    for (var t, e = 1, n = arguments.length; e < n; e++) {
      t = arguments[e];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (r[a] = t[a]);
    }
    return r;
  }, Br.apply(this, arguments);
}, er = function(r, t, e) {
  return O({
    value: r,
    next: t,
    start: e
  });
}, nr = function(r, t, e) {
  return t === void 0 && (t = Ne), e === void 0 && (e = !1), P({
    input: r,
    expected: t,
    fatal: e
  });
}, Dp = function(r, t) {
  return Br({}, r, { expected: t });
}, Up = function(r, t) {
  return Gp().concat(r, t);
}, Gp = function() {
  return {
    concat: function(r, t) {
      return r.input.cursor < t.input.cursor ? Xl().concat(r, t) : r.input.cursor > t.input.cursor ? Pr().concat(r, t) : Ql({
        input: Pr(),
        fatal: Pr(),
        expected: Ep()
      }).concat(r, t);
    }
  };
}, Bp = function(r, t) {
  return t === void 0 && (t = 0), {
    buffer: r,
    cursor: t
  };
}, Fp = function(r) {
  return wp(r.cursor, r.buffer);
}, Kp = function(r) {
  return r.cursor >= r.buffer.length;
}, Hp = function(r) {
  return v(Fp(r), Gr(function(t) {
    return { value: t, next: { buffer: r.buffer, cursor: r.cursor + 1 } };
  }));
}, Z = globalThis && globalThis.__assign || function() {
  return Z = Object.assign || function(r) {
    for (var t, e = 1, n = arguments.length; e < n; e++) {
      t = arguments[e];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (r[a] = t[a]);
    }
    return r;
  }, Z.apply(this, arguments);
}, rt = function(r) {
  return function(t) {
    return er(r, t, t);
  };
}, Vp = function() {
  return function(r) {
    return nr(r);
  };
}, Wp = function(r) {
  return function() {
    return nr(r);
  };
}, b = function(r) {
  return v(Xp(zp()), M(function(t) {
    var e = t[0], n = t[1];
    return r(e) ? G(e) : Wp(n);
  }));
}, _ = function(r, t) {
  return function(e) {
    return v(r(e), wr(function(n) {
      return Dp(n, [t]);
    }));
  };
}, zp = function() {
  return function(r) {
    return v(Hp(r), kr(function() {
      return nr(r);
    }, function(t) {
      var e = t.value, n = t.next;
      return er(e, n, r);
    }));
  };
}, Jp = function(r, t) {
  return function(e) {
    return v(r(e), Yt(function(n) {
      return v(t(n.value)(n.next), Yt(function(a) {
        return er(a.value, a.next, e);
      }));
    }));
  };
}, jr = function(r, t) {
  return function(e) {
    var n = r(e);
    return Mp(n) || n.left.fatal ? n : v(t()(e), wr(function(a) {
      return Up(n.left, a);
    }));
  };
}, Xp = function(r) {
  return function(t) {
    return v(r(t), tr(function(e) {
      return Z({}, e, { value: [e.value, t] });
    }));
  };
}, Ge = function(r) {
  return N(function() {
    return G(r.empty);
  });
}, tt = function() {
  return _(function(r) {
    return Kp(r) ? er(void 0, r, r) : nr(r);
  }, "end of file");
}, Qp = function(r) {
  return v(et(r), N(function() {
    return G(Ne);
  }));
}, et = function(r) {
  return v(r, M(function(t) {
    return at(Sp(t), function(e) {
      return v(r, d(function(n) {
        return P(Op(e, n));
      }), N(function() {
        return G(O(e));
      }));
    });
  }));
}, Yp = function(r, t) {
  return function(e) {
    return v(r, M(function() {
      return e;
    }), ot(function() {
      return t;
    }));
  };
}, Be = function(r) {
  return Yp(r, r);
}, Zp = function(r) {
  return Qp(b(q(r)));
}, nt = function(r, t) {
  return v(t, d(function() {
    return Tp;
  }), N(function() {
    return kp(r, t);
  }));
}, kp = function(r, t) {
  return v(r, M(function(e) {
    return at(rv(e), function(n) {
      return v(t, d(function() {
        return O(n);
      }), N(function() {
        return v(r, d(function(a) {
          return P(tv(n, a));
        }));
      }));
    });
  }));
}, ar = function(r, t) {
  return function(e) {
    return v(r(e), tr(function(n) {
      return Z({}, n, { value: t(n.value) });
    }));
  };
}, Fe = function(r, t) {
  return Cr(r, function(e) {
    return ar(t, e);
  });
}, Cr = function(r, t) {
  return Jp(r, t);
}, at = function(r, t) {
  var e = function(n) {
    return function(a) {
      return U(a.value) ? P({ value: a.value.left, stream: a.next }) : O(er(a.value.right, a.next, n));
    };
  };
  return function(n) {
    return Rp({ value: r, stream: n }, function(a) {
      var o = t(a.value)(a.stream);
      return U(o) ? O(nr(a.stream, o.left.expected, o.left.fatal)) : e(n)(o.right);
    });
  };
}, xp = function(r, t) {
  return jr(r, t);
}, d = function(r) {
  return function(t) {
    return ar(t, r);
  };
}, G = rt, M = function(r) {
  return function(t) {
    return Cr(t, r);
  };
}, ot = function(r) {
  return function(t) {
    return Cr(t, function(e) {
      return ar(r(e), function() {
        return e;
      });
    });
  };
}, N = function(r) {
  return function(t) {
    return xp(t, r);
  };
}, rg = "Parser", tg = function(r) {
  return {
    concat: function(t, e) {
      return Fe(ar(t, function(n) {
        return function(a) {
          return r.concat(n, a);
        };
      }), e);
    }
  };
}, eg = function(r) {
  return Z({}, tg(r), { empty: rt(r.empty) });
}, ng = {
  URI: rg,
  map: ar,
  ap: Fe,
  chain: Cr,
  chainRec: at
}, Ke = function(r, t, e) {
  var n;
  return Object.assign({}, r, (n = {}, n[t] = e, n));
}, He = function(r) {
  return function(t) {
    return v(t, d(function(e) {
      return Ke({}, r, e);
    }));
  };
}, Fr = function(r, t) {
  return function(e) {
    return v(e, M(function(n) {
      return v(t(n), d(function(a) {
        return Ke(n, r, a);
      }));
    }));
  };
}, ag = function(r) {
  return zl(r)(r.empty);
};
Jl.concat;
var og = ag, it = {
  concat: Yl.concat,
  empty: ""
}, ig = Ge(it), B = function(r) {
  return _(b(function(t) {
    return t === r;
  }), '"' + r + '"');
}, ug = function(r) {
  return _(b(function(t) {
    return t !== r;
  }), 'anything but "' + r + '"');
}, ut = function(r) {
  return ig(or(r));
}, or = function(r) {
  return v(et(r), d(function(t) {
    return t.join("");
  }));
}, st = function(r) {
  return "0123456789".indexOf(r) !== -1;
}, Kr = _(b(st), "a digit"), sg = /^\s$/, Ve = function(r) {
  return sg.test(r);
}, We = _(b(Ve), "a whitespace"), cg = function(r) {
  return r === "_";
}, ir = function(r) {
  return /[a-z]/.test(r.toLowerCase());
}, ze = function(r) {
  return ir(r) || st(r) || cg(r);
};
_(b(ze), "a word character");
_(b(ir), "a letter");
var fg = function(r) {
  return r.toLowerCase() !== r.toUpperCase();
};
_(b(fg), "an unicode letter");
var Je = function(r) {
  return ir(r) && r === r.toUpperCase();
};
_(b(Je), "an upper case letter");
var Xe = function(r) {
  return ir(r) && r === r.toLowerCase();
};
_(b(Xe), "a lower case letter");
_(b(q(st)), "a non-digit");
var Qe = _(b(q(Ve)), "a non-whitespace character");
_(b(q(ze)), "a non-word character");
_(b(q(ir)), "a non-letter character");
_(b(q(Je)), "anything but an upper case letter");
_(b(q(Xe)), "anything but a lower case letter");
var Ye = function(r) {
  return _(ng.chainRec(r, function(t) {
    return v(pg(0, t), kr(function() {
      return G(O(r));
    }, function(e) {
      return v(B(e), M(function() {
        return G(P(t.slice(1)));
      }));
    }));
  }), JSON.stringify(r));
}, Hr = og(eg(it)), k = Ge(it), lg = function(r) {
  return k(vg(r));
}, vg = function(r) {
  return v(et(r), d(function(t) {
    return t.join("");
  }));
}, pg = function(r, t) {
  return r >= 0 && r < t.length ? rr(t.charAt(r)) : Or;
}, gg = ut(We);
or(We);
ut(Qe);
or(Qe);
var yg = function(r) {
  var t = +r;
  return isNaN(t) || r === "" ? Or : rr(t);
};
_(v(Hr([k(B("-")), or(Kr)]), d(function(r) {
  return +r;
})), "an integer");
_(v(Hr([k(B("-")), ut(Kr), k(Hr([B("."), or(Kr)]))]), M(function(r) {
  return v(yg(r), kr(function() {
    return Vp();
  }, rt));
})), "a float");
var hg = Be(B('"'))(lg(jr(Ye('\\"'), function() {
  return ug('"');
})));
function Ze(r) {
  return function(t) {
    return t(Bp(r.split("")));
  };
}
const Zt = (r, t) => (e) => ({
  ...e,
  [r]: t(e[r])
}), $g = ["#", ":"], F = Be(gg), ct = (r) => (t) => t.join(r), ft = (r) => h(
  Zp((t) => r.includes(t)),
  d(ct(""))
), dg = E(
  ft,
  ot(() => b(() => !0))
), ke = h(
  hg,
  d((r) => JSON.parse(`"${r}"`))
), _g = h(
  F(ke),
  N(
    () => h(
      ft([":", `
`]),
      d(je)
    )
  )
), bg = h(
  F(ke),
  N(
    () => h(
      ft([`
`]),
      d(je)
    )
  )
), xe = h(
  k(Ye("#")),
  d($p(Ce))
), rn = h(
  F(xe),
  He("commented"),
  Fr("key", () => F(_g)),
  ot(() => B(":")),
  Fr("value", () => bg)
), Tg = h(
  F(xe),
  He("commented"),
  Fr("key", () => jr(
    dg([`
`]),
    () => h(
      nt(b((r) => !0), tt()),
      d(E(
        Me,
        ct("")
      ))
    )
  )),
  d(E(
    De(({ key: r }) => !Ce(r))
  ))
), Ag = h(
  nt(F(rn), tt())
), mg = h(
  nt(
    jr(
      h(rn, d(rr)),
      () => h(
        Tg,
        d(E(
          Gr((r) => ({ ...r, value: "" }))
        ))
      )
    ),
    tt()
  ),
  d(E(
    bp(E(
      De(Pp),
      Gr((r) => r.value)
    ))
  ))
), Sg = (r) => {
  if ($g.some((n) => r.includes(n)))
    return !0;
  const t = JSON.stringify(r);
  return t.substring(1, t.length - 1).trim() !== r;
}, kt = (r) => Sg(r) ? JSON.stringify(r) : r, Rg = (r) => h(
  r,
  jp(
    E(
      Zt("key", kt),
      Zt("value", kt),
      ({ key: t, value: e, active: n }) => n ? `${t}: ${e}` : `# ${t}: ${e}`
    )
  ),
  ct(`
`)
), Og = (r) => h(
  mg,
  Ze(r),
  wr((t) => ({
    message: `Expected ${t.expected.map((e) => `'${e}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  tr(
    ({ value: t }) => h(
      t,
      qe(
        ({ key: e, value: n, commented: a }) => ({
          active: !a,
          key: e,
          value: n
        })
      )
    )
  )
), qg = (r) => h(
  Ag,
  Ze(r),
  wr((t) => ({
    message: `Expected ${t.expected.map((e) => `'${e}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  tr(
    ({ value: t }) => h(
      t,
      qe(
        ({ key: e, value: n, commented: a }) => ({
          active: !a,
          key: e,
          value: n
        })
      )
    )
  )
), Mg = E(
  Og,
  tr(Me),
  xr(() => [])
), gr = /<<([^>]*)>>/g, yr = 10, tn = "ENV_EXPAND_LOOP";
function wg(r, t) {
  let e = r, n = 0;
  for (; e.match(gr) != null && n <= yr; )
    e = e.replace(gr, (a) => {
      const o = t.find(
        (i) => i.key === a.replace(/[<>]/g, "")
      );
      return o ? o.value : a;
    }), n++;
  return n > yr ? P(tn) : O(e);
}
const Ng = (r, t) => h(
  wg(r, t),
  xr(() => r)
);
function jg(r, t) {
  if (!t || !r)
    return O(r);
  let e = r, n = 0;
  for (; e.match(gr) != null && n <= yr; )
    e = decodeURI(encodeURI(e)).replace(
      gr,
      (a, o) => {
        var i;
        return ((i = t.find((u) => u.key === o)) == null ? void 0 : i.value) || "";
      }
    ), n++;
  return n > yr ? P(tn) : O(e);
}
const Dg = (r, t) => h(
  jg(r, t),
  xr(() => r)
);
export {
  pr as GQL_REQ_SCHEMA_VERSION,
  sp as HoppRESTRequestEq,
  Ie as RESTReqSchemaVersion,
  Eg as isEqualHoppRESTRequest,
  cp as isHoppRESTRequest,
  Cg as knownContentTypes,
  Le as makeCollection,
  Lg as makeGQLRequest,
  Ig as makeRESTRequest,
  Ng as parseBodyEnvVariables,
  wg as parseBodyEnvVariablesE,
  vp as parseOldAuth,
  Mg as parseRawKeyValueEntries,
  Og as parseRawKeyValueEntriesE,
  Dg as parseTemplateString,
  jg as parseTemplateStringE,
  Rg as rawKeyValueEntriesToString,
  Pg as safelyExtractRESTRequest,
  qg as strictParseRawKeyValueEntriesE,
  pp as translateToGQLRequest,
  hp as translateToNewGQLCollection,
  yp as translateToNewRESTCollection,
  lp as translateToNewRequest
};

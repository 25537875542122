import { pipe } from "fp-ts/function"
import * as TE from "fp-ts/TaskEither"
import { runMutation } from "../GQLClient"
import { ProjectName } from "../types/ProjectName"
import {
  CreateProjectDocument,
  CreateProjectMutation,
  CreateProjectMutationVariables,
  DeleteProjectDocument,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  // LeaveProjectDocument,
  // LeaveProjectMutation,
  // LeaveProjectMutationVariables,
  // RemoveProjectMemberDocument,
  // RemoveProjectMemberMutation,
  // RemoveProjectMemberMutationVariables,
  RenameProjectDocument,
  RenameProjectMutation,
  RenameProjectMutationVariables,
  // ServiceMemberRole,
  // UpdateProjectMemberRoleDocument,
  // UpdateProjectMemberRoleMutation,
  // UpdateProjectMemberRoleMutationVariables,
} from "../graphql"

type CreateProjectErrors = "team/name_invalid" | "ea/not_invite_or_admin"

type DeleteProjectErrors =
  | "team/not_required_role"
  | "team/invalid_id"
  | "team/member_not_found"
  | "ea/not_invite_or_admin"

type RenameProjectErrors =
  | "ea/not_invite_or_admin"
  | "team/invalid_id"
  | "team/not_required_role"

export const createProject = (name: ProjectName, orgId: string) =>
  pipe(
    runMutation<
      CreateProjectMutation,
      CreateProjectMutationVariables,
      CreateProjectErrors
    >(CreateProjectDocument, {
      name,
      orgId,
    }),
    TE.map(({ createProject }) => createProject)
  )

export const deleteProject = (projectID: string) =>
  runMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables,
    DeleteProjectErrors
  >(DeleteProjectDocument, {
    projectID,
  })

export const renameProject = (projectID: string, newName: ProjectName) =>
  pipe(
    runMutation<
      RenameProjectMutation,
      RenameProjectMutationVariables,
      RenameProjectErrors
    >(RenameProjectDocument, {
      newName,
      projectID,
    }),
    TE.map(({ renameProject }) => renameProject)
  )

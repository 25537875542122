<template>
  <div>
    <header
      class="flex items-center justify-between flex-1 flex-shrink-0 px-2 py-2 space-x-2 overflow-x-auto overflow-y-hidden"
    >
      <div
        class="inline-flex items-center justify-start flex-1 space-x-2"
        :style="{
          paddingTop: platform.ui?.appHeader?.paddingTop?.value,
          paddingLeft: platform.ui?.appHeader?.paddingLeft?.value,
        }"
      >
        <div class="logo">
          <img src="../../../assets/images/logo.png" alt="logo" to="/" />
        </div>
        <HoppButtonSecondary
          class="tracking-wide !font-bold !text-secondaryDark hover:bg-primaryDark focus-visible:bg-primaryDark uppercase"
          :label="t('app.name')"
          to="/"
        />
        <!-- <AppGitHubStarButton class="mt-1.5 transition" /> -->
      </div>
      <div class="inline-flex items-center space-x-2">
        <HoppButtonSecondary
          v-tippy="{ theme: 'tooltip', allowHTML: true }"
          :title="`${t('app.search')} <kbd>/</kbd>`"
          :icon="IconSearch"
          class="rounded hover:bg-primaryDark focus-visible:bg-primaryDark"
          @click="invokeAction('modals.search.toggle')"
        />
        <div class="inline-flex items-center space-x-2">
          <div
            class="flex border divide-x rounded bg-green-500/15 divide-green-600/25 border-green-600/25 focus-within:bg-green-400/10 focus-within:border-green-800/50 focus-within:divide-green-800/50 hover:bg-green-400/10 hover:border-green-800/50 hover:divide-green-800/50"
          >
            <HoppButtonSecondary
              v-if="organizationRole === OrganizationUserRole.Admin"
              v-tippy="{ theme: 'tooltip' }"
              :title="t('project.tippyCreate')"
              :icon="IconPlus"
              :label="
                !organization?.myProjects?.length
                  ? t('project.tippyCreate')
                  : ''
              "
              class="py-1.75 !text-green-500 !focus-visible:text-green-600 !hover:text-green-600"
              @click="displayModalAdd(true)"
            />
            <HoppButtonSecondary
              v-if="
                workspaceBoundary?.type === 'organization' &&
                organizationRole === OrganizationUserRole.Admin
              "
              v-tippy="{ theme: 'tooltip' }"
              :title="t('project.tippySetting')"
              :icon="IconSettings"
              class="py-1.75 !text-green-500 !focus-visible:text-green-600 !hover:text-green-600"
              @click="handleProjectEdit"
            />
          </div>
          <!-- Project -->
          <tippy
            v-if="
              (organization?.myProjects?.length &&
                organizationRole === OrganizationUserRole.Admin) ||
              organizationRole !== OrganizationUserRole.Admin
            "
            interactive
            trigger="click"
            theme="popover"
            :on-shown="() => accountActions.focus()"
          >
            <HoppButtonSecondary
              v-tippy="{ theme: 'tooltip' }"
              :title="t('project.change')"
              :label="mdAndLarger ? projectName : ``"
              :icon="IconUsers"
              class="pr-8 select-wrapper rounded bg-blue-500/15 py-1.75 border border-blue-600/25 !text-blue-500 focus-visible:bg-blue-400/10 focus-visible:border-blue-800/50 !focus-visible:text-blue-600 hover:bg-blue-400/10 hover:border-blue-800/50 !hover:text-blue-600"
            />
            <template #content="{ hide }">
              <div
                ref="accountActions"
                class="flex flex-col focus:outline-none"
                tabindex="0"
                @keyup.escape="hide()"
                @click="hide()"
              >
                <ProjectSelector />
              </div>
            </template>
          </tippy>

          <!-- profile -->
          <span v-if="currentUser" class="px-2">
            <tippy
              interactive
              trigger="click"
              theme="popover"
              :on-shown="() => tippyActions.focus()"
            >
              <HoppSmartPicture
                v-if="currentUser?.photoURL"
                v-tippy="{
                  theme: 'tooltip',
                }"
                :url="currentUser?.photoURL"
                :alt="
                  currentUser?.displayName ||
                  t('profile.default_hopp_displayname')
                "
                :title="
                  currentUser?.displayName ||
                  currentUser?.email ||
                  t('profile.default_hopp_displayname')
                "
                indicator
                :indicator-styles="
                  network.isOnline ? 'bg-green-500' : 'bg-red-500'
                "
              />
              <HoppSmartPicture
                v-else
                v-tippy="{ theme: 'tooltip' }"
                :title="
                  currentUser?.displayName ||
                  currentUser?.email ||
                  t('profile.default_hopp_displayname')
                "
                :initial="currentUser?.displayName || currentUser?.email"
                indicator
                :indicator-styles="
                  network.isOnline ? 'bg-green-500' : 'bg-red-500'
                "
              />
              <template #content="{ hide }">
                <div
                  ref="tippyActions"
                  class="flex flex-col focus:outline-none"
                  tabindex="0"
                  @keyup.p="profile.$el.click()"
                  @keyup.s="settings.$el.click()"
                  @keyup.l="logout.$el.click()"
                  @keyup.escape="hide()"
                >
                  <div class="flex flex-col px-2 text-tiny">
                    <span class="inline-flex font-semibold truncate">
                      {{
                        currentUser?.displayName ||
                        t("profile.default_hopp_displayname")
                      }}
                    </span>
                    <span class="inline-flex truncate text-secondaryLight">
                      {{ currentUser?.email }}
                    </span>
                  </div>
                  <hr />
                  <HoppSmartItem
                    ref="profile"
                    to="/profile"
                    :icon="IconUser"
                    :label="t('navigation.profile')"
                    :shortcut="['P']"
                    @click="hide()"
                  />
                  <HoppSmartItem
                    ref="settings"
                    to="/settings"
                    :icon="IconSettings"
                    :label="t('navigation.settings')"
                    :shortcut="['S']"
                    @click="hide()"
                  />
                  <FirebaseLogout
                    ref="logout"
                    :shortcut="['L']"
                    @confirm-logout="hide()"
                  />
                </div>
              </template>
            </tippy>
          </span>
        </div>
      </div>
    </header>
    <AppAnnouncement v-if="!network.isOnline" />
    <TeamsModal
      :show="showProjectsModal"
      @hide-modal="showProjectsModal = false"
    />
    <ProjectEdit
      :show="showModalEdit"
      :editing-team="editingProjectName"
      :editing-team-i-d="editingProjectID"
      @hide-modal="displayModalEdit(false)"
      @refetch-teams="refetchProjects"
    />
    <ProjectAdd :show="showModalAdd" @hide-modal="displayModalAdd(false)" />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue"
import IconUser from "~icons/lucide/user"
import IconUsers from "~icons/lucide/users"
import IconSettings from "~icons/lucide/settings"
import IconPlus from "~icons/lucide/plus"
import IconSearch from "~icons/lucide/search"
import { breakpointsTailwind, useBreakpoints, useNetwork } from "@vueuse/core"
import { platform } from "~/platform"
import { useI18n } from "@composables/i18n"
import { useReadonlyStream } from "@composables/stream"
import { invokeAction } from "@helpers/actions"
import {
  workspaceBoundaryStatus$,
  changeWorkspaceBoundary,
} from "~/newstore/workspaceBoundary"
import ProjectListAdapter from "~/helpers/projects/ProjectListAdapter"
import { onLoggedIn } from "~/composables/auth"
import { OrganizationUserRole } from "~/helpers/backend/graphql"
import { useLocalState } from "~/newstore/localstate"
import TeamListAdapter from "~/helpers/teams/TeamListAdapter"
import { usePageHead } from "~/composables/head"
import { APP_INFO } from "~/../meta"

const t = useI18n()
/**
 * Once the PWA code is initialized, this holds a method
 * that can be called to show the user the installation
 * prompt.
 */
const showModalAdd = ref(false)
const showModalEdit = ref(false)

const showProjectsModal = ref(false)

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greater("md")

const network = reactive(useNetwork())

const REMEMBERED_PROJECT_ID = useLocalState("REMEMBERED_PROJECT_ID")

const currentUser = useReadonlyStream(
  platform.auth.getProbableUserStream(),
  platform.auth.getProbableUser()
)

// projectListAdapter
const projectListAdapter = new ProjectListAdapter(true)
const teamListAdapter = new TeamListAdapter(null)
const organizationRole = useReadonlyStream(
  projectListAdapter.organizationRole$,
  null
)
const organization = useReadonlyStream(projectListAdapter.organization$, null)

const workspaceBoundary = useReadonlyStream(workspaceBoundaryStatus$, null)

usePageHead({
  title: computed(() =>
    workspaceBoundary.value
      ? `${APP_INFO.name} • ${workspaceBoundary.value?.projectName}`
      : (APP_INFO.name as string)
  ),
})

const projectName = computed(() => {
  return workspaceBoundary.value?.projectName || t("project.select_project")
})

const refetchProjects = () => {
  projectListAdapter.fetchList()
}

onLoggedIn(() => {
  !projectListAdapter.isInitialized && projectListAdapter.initialize()
})

watch(
  () => organization.value,
  (organization) => {
    if (organization?.myProjects?.length) {
      const projectID =
        workspaceBoundary.value?.projectID || REMEMBERED_PROJECT_ID.value
      const projectInList =
        (projectID &&
          organization.myProjects.find((p) => p.id === projectID)) ||
        organization.myProjects[0]
      changeWorkspaceBoundary({
        orgID: organization.id,
        orgRole: organization.myRole,
        orgName: organization.name,
        projectID: projectInList.id,
        projectName: projectInList.name,
        isAdmin: projectInList.isAdmin,
        type: "organization",
      })
      REMEMBERED_PROJECT_ID.value = projectInList.id
      return
    }
    changeWorkspaceBoundary(null)
  }
)

watch(
  () => workspaceBoundary.value,
  (newWorkspaceBoundary) => {
    teamListAdapter.changeProjectID(newWorkspaceBoundary?.projectID || null)
  }
)

const editingProjectName = ref<{ name: string }>({ name: "" })
const editingProjectID = ref("")

const displayModalEdit = (show: boolean) => {
  showModalEdit.value = show
}

const handleProjectEdit = () => {
  if (!workspaceBoundary) {
    return
  }
  if (workspaceBoundary.value?.type === "organization") {
    editingProjectID.value = workspaceBoundary.value.projectID
    editingProjectName.value = { name: workspaceBoundary.value.projectName }
    displayModalEdit(true)
  }
}

const displayModalAdd = (shouldDisplay: boolean) => {
  showModalAdd.value = shouldDisplay
}

// Template refs
const tippyActions = ref<any | null>(null)
const profile = ref<any | null>(null)
const settings = ref<any | null>(null)
const logout = ref<any | null>(null)
const accountActions = ref<any | null>(null)
</script>

<style lang="scss" scoped>
.logo {
  width: 64px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

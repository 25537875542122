import { platform } from "./../../platform"
import * as E from "fp-ts/Either"
import { BehaviorSubject } from "rxjs"
import { GQLError, runGQLQuery } from "../backend/GQLClient"
import {
  GetMyOrganizationDocument,
  GetMyOrganizationQuery,
  OrganizationUserRole,
} from "../backend/graphql"

// const BACKEND_PAGE_SIZE = 10
// const POLL_DURATION = 10000

export default class ProjectListAdapter {
  static _instance: ProjectListAdapter
  error$ = new BehaviorSubject<GQLError<string> | null>(null)
  loading$ = new BehaviorSubject<boolean>(false)
  projectList$ = new BehaviorSubject<
    GetMyOrganizationQuery["organization"]["myProjects"]
  >([])
  organizationRole$ = new BehaviorSubject<OrganizationUserRole | null>(null)
  organization$ = new BehaviorSubject<
    GetMyOrganizationQuery["organization"] | null
  >(null)

  private timeoutHandle: ReturnType<typeof setTimeout> | null = null
  private isDispose = false

  public isInitialized = false

  constructor(deferInit = false) {
    const instance = ProjectListAdapter._instance
    if (ProjectListAdapter._instance) {
      return instance
    }
    ProjectListAdapter._instance = this
    if (!deferInit) this.initialize()
  }

  initialize() {
    if (this.timeoutHandle) throw new Error(`Adapter already initialized`)
    if (this.isDispose) throw new Error(`Adapter has been disposed`)

    this.isInitialized = true
    this.fetchList()
  }

  public dispose() {
    this.isDispose = true
    clearTimeout(this.timeoutHandle as any)
    this.timeoutHandle = null
    this.isInitialized = false
  }

  async fetchList() {
    const currentUser = platform.auth.getCurrentUser()

    // if the authIdToken is not present, don't fetch the teams list, as it will fail anyway
    if (!currentUser) return
    this.loading$.next(true)

    const results: GetMyOrganizationQuery["organization"]["myProjects"] = []
    let role: GetMyOrganizationQuery["organization"]["myRole"] =
      OrganizationUserRole.Member

    // while (true) {
    //   const result = await runGQLQuery({
    //     query: GetMyOrganizationDocument,
    //     variables: {
    //       orgID: "ttlab",
    //     },
    //   })

    //   if (E.isLeft(result)) {
    //     this.error$.next(result.left)
    //     throw new Error(
    //       `Failed fetching teams list: ${JSON.stringify(result.left)}`
    //     )
    //   }

    //   results.push(...result.right.organization.myProjects)
    //   role = result.right.organization.myRole as OrganizationUserRole
    //   // If we don't have full elements in the list, then the list is done usually, so lets stop
    //   if (result.right.organization.myProjects.length !== BACKEND_PAGE_SIZE)
    //     break
    // }
    const result = await runGQLQuery({
      query: GetMyOrganizationDocument,
      variables: {
        orgID: "ttlab",
      },
    })

    if (E.isLeft(result)) {
      this.error$.next(result.left)
      throw new Error(
        `Failed fetching teams list: ${JSON.stringify(result.left)}`
      )
    }
    if (result.right.organization.myRole === OrganizationUserRole.SuperAdmin) {
      result.right.organization.myRole = OrganizationUserRole.Admin
    }

    this.organization$.next(result.right.organization)

    role = result.right.organization.myRole as OrganizationUserRole
    this.organizationRole$.next(role)

    results.push(...result.right.organization.myProjects)

    this.projectList$.next(results)

    this.loading$.next(false)
  }
}

import { distinctUntilChanged, pluck } from "rxjs"
import DispatchingStore, { defineDispatchers } from "./DispatchingStore"
import { OrganizationUserRole } from "~/helpers/backend/graphql"

type WorkspaceBoundary = {
  orgID: string
  orgName: string
  projectID: string
  projectName: string
  orgRole: OrganizationUserRole | undefined | null
  isAdmin: boolean
  type: "organization"
} | null

type WorkspaceBoundaryState = {
  boundary: WorkspaceBoundary
}

const initialState: WorkspaceBoundaryState = {
  boundary: null,
}

const dispatchers = defineDispatchers({
  changeWorkspaceBoundary(_, { boundary }: { boundary: WorkspaceBoundary }) {
    return {
      boundary,
    }
  },
  updateWorkspaceBoundaryName(
    _,
    {
      boundary,
      newProjectName,
    }: { boundary: WorkspaceBoundary; newProjectName: string }
  ) {
    if (boundary?.projectID) {
      return {
        boundary: {
          ...boundary,
          projectName: newProjectName,
        },
      }
    }
    return { boundary }
  },
})

export const workspaceBoundaryStore = new DispatchingStore(
  initialState,
  dispatchers
)

export const workspaceBoundaryStatus$ = workspaceBoundaryStore.subject$.pipe(
  pluck("boundary"),
  distinctUntilChanged()
)

export function changeWorkspaceBoundary(boundary: WorkspaceBoundary) {
  workspaceBoundaryStore.dispatch({
    dispatcher: "changeWorkspaceBoundary",
    payload: { boundary },
  })
}

export function updateWorkspaceProjectName(
  boundary: WorkspaceBoundary,
  newProjectName: string
) {
  workspaceBoundaryStore.dispatch({
    dispatcher: "updateWorkspaceBoundaryName",
    payload: { boundary, newProjectName },
  })
}

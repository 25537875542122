<template>
  <div>
    <div class="flex flex-col">
      <div v-if="loading" class="flex flex-col items-center justify-center p-4">
        <HoppSmartSpinner class="mb-4" />
        <span class="text-secondaryLight">{{ t("state.loading") }}</span>
      </div>
      <div
        v-if="!loading && myProjects.length === 0"
        class="flex flex-col items-center justify-center flex-1 p-4 text-secondaryLight"
      >
        <img
          :src="`/images/states/${colorMode.value}/add_group.svg`"
          loading="lazy"
          class="inline-flex flex-col object-contain object-center w-16 h-16 mb-8"
          :alt="`${t('empty.teams')}`"
        />
        <span class="mb-4 text-center">
          {{ t("empty.projects") }}
        </span>
      </div>
      <div v-else-if="!loading" class="flex flex-col">
        <HoppSmartItem
          v-for="(pro, index) in myProjects"
          :key="`project-${String(index)}`"
          :icon="IconUsers"
          :label="pro.name"
          :info-icon="isActiveWorkspace(pro.id) ? IconDone : undefined"
          :active-info-icon="isActiveWorkspace(pro.id)"
          @click="switchToTeamWorkspace(pro)"
        />
      </div>
      <div
        v-if="!loading && projectListAdapterError"
        class="flex flex-col items-center py-4"
      >
        <icon-lucide-help-circle class="mb-4 svg-icons" />
        {{ t("error.something_went_wrong") }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue"
import { onLoggedIn } from "~/composables/auth"
import { useReadonlyStream } from "~/composables/stream"
import ProjectListAdapter from "~/helpers/projects/ProjectListAdapter"
import { useI18n } from "@composables/i18n"
import IconUsers from "~icons/lucide/users"
import { useColorMode } from "@composables/theming"
import {
  workspaceBoundaryStatus$,
  changeWorkspaceBoundary,
} from "~/newstore/workspaceBoundary"
import { GetMyOrganizationQuery } from "~/helpers/backend/graphql"
import IconDone from "~icons/lucide/check"
import { useLocalState } from "~/newstore/localstate"

const t = useI18n()
const colorMode = useColorMode()

const projectListAdapter = new ProjectListAdapter(true)
const myProjects = useReadonlyStream(projectListAdapter.projectList$, [])
const organization = useReadonlyStream(projectListAdapter.organization$, null)
const isProjectListLoading = useReadonlyStream(
  projectListAdapter.loading$,
  false
)
const projectListAdapterError = useReadonlyStream(
  projectListAdapter.error$,
  null
)
const REMEMBERED_PROJECT_ID = useLocalState("REMEMBERED_PROJECT_ID")

const loading = computed(() => {
  return isProjectListLoading.value && !organization.value?.myProjects?.length
})

const project = useReadonlyStream(workspaceBoundaryStatus$, null)

const isActiveWorkspace = computed(() => (id: string) => {
  return project.value?.projectID === id
})

const switchToTeamWorkspace = (
  project: GetMyOrganizationQuery["organization"]["myProjects"][number]
) => {
  REMEMBERED_PROJECT_ID.value = project.id
  changeWorkspaceBoundary({
    projectID: project.id,
    projectName: project.name,
    orgID: organization.value!.id,
    orgName: organization.value!.name,
    orgRole: organization.value!.myRole,
    isAdmin: project.isAdmin,
    type: "organization",
  })
}

onLoggedIn(() => {
  projectListAdapter.initialize()
})
</script>

<template>
  <HoppSmartModal
    v-if="show"
    dialog
    :title="t('project.titleSetting')"
    @close="hideModal"
  >
    <template #body>
      <div class="flex flex-col">
        <input
          id="selectLabelTeamEdit"
          v-model="name"
          v-focus
          class="input floating-input"
          placeholder=" "
          type="text"
          autocomplete="off"
          @keyup.enter="saveProject"
        />
        <label for="selectLabelTeamEdit">
          {{ t("action.label") }}
        </label>
      </div>
    </template>
    <template #footer>
      <span class="flex space-x-2">
        <HoppButtonPrimary
          :label="t('action.save')"
          :loading="isLoadingCreate"
          outline
          @click="saveProject"
        />
        <HoppButtonSecondary
          :label="t('action.cancel')"
          outline
          filled
          @click="hideModal"
        />
      </span>
      <HoppButtonSecondary
        :loading="isLoadingDelete"
        :label="t('action.delete')"
        outline
        filled
        color="red"
        @click="handleClickDelete"
      />
    </template>
  </HoppSmartModal>
  <HoppSmartConfirmModal
    :show="isShowModalConfirmDelete"
    :title="`${t('confirm.delete_project')}`"
    @hide-modal="isShowModalConfirmDelete = false"
    @resolve="handleDelete"
  />
</template>

<script setup lang="ts">
import * as E from "fp-ts/Either"
import { ref, toRef, watch } from "vue"
import {
  GetProjectDetailDocument,
  GetProjectDetailQuery,
  GetProjectDetailQueryVariables,
} from "~/helpers/backend/graphql"
import {
  renameProject,
  deleteProject,
} from "~/helpers/backend/mutations/Project"
import { ProjectNameCodec } from "~/helpers/backend/types/ProjectName"
import { useGQLQuery } from "~/composables/graphql"
import { useI18n } from "@composables/i18n"
import { useToast } from "@composables/toast"
import { useLocalState } from "~/newstore/localstate"
import ProjectListAdapter from "~/helpers/projects/ProjectListAdapter"

const t = useI18n()

const emit = defineEmits<{
  (e: "hide-modal"): void
}>()

// Template refs

const props = defineProps<{
  show: boolean
  editingTeam: {
    name: string
  }
  editingTeamID: string
}>()

const toast = useToast()

const name = toRef(props.editingTeam, "name")

const isShowModalConfirmDelete = ref(false)

const REMEMBERED_PROJECT_ID = useLocalState("REMEMBERED_PROJECT_ID")

watch(
  () => props.editingTeam.name,
  (newName: string) => {
    name.value = newName
  }
)

watch(
  () => props.editingTeamID,
  (projectID: string) => {
    teamDetails.execute({ projectID })
  }
)

const teamDetails = useGQLQuery<
  GetProjectDetailQuery,
  GetProjectDetailQueryVariables,
  ""
>({
  query: GetProjectDetailDocument,
  variables: {
    projectID: props.editingTeamID,
  },
})

watch(
  () => props.show,
  (show) => {
    if (!show) {
      teamDetails.pause()
    } else {
      teamDetails.unpause()
    }
  }
)

const projectListAdapter = new ProjectListAdapter(true)

const isLoadingCreate = ref(false)
const isLoadingDelete = ref(false)

const handleClickDelete = () => {
  isShowModalConfirmDelete.value = true
}

const handleDelete = async () => {
  isLoadingDelete.value = true
  if (props.editingTeamID) {
    const res = await deleteProject(props.editingTeamID)()
    if (E.isLeft(res)) {
      toast.error(`${t("project.deleteFailed")}`)
    } else {
      toast.success(`${t("project.deleteSuccess")}`)
      REMEMBERED_PROJECT_ID.value = undefined
      projectListAdapter.fetchList()
    }
    hideModal()
  } else {
    toast.error(`${t("project.deleteFailed")}`)
  }
  isLoadingDelete.value = false
}

const saveProject = async () => {
  isLoadingCreate.value = true
  if (name.value !== "") {
    if (ProjectNameCodec.is(name.value)) {
      const res = await renameProject(props.editingTeamID, name.value)()
      if (E.isLeft(res)) {
        toast.error(`${t("project.saveFailed")}`)
      } else {
        toast.success(`${t("project.saveSuccess")}`)
        projectListAdapter.fetchList()
      }
      hideModal()
    } else {
      toast.error(`${t("project.saveFailed")}`)
    }
  } else {
    toast.error(`${t("project.saveFailed")}`)
  }
  isLoadingCreate.value = false
}

const hideModal = () => {
  emit("hide-modal")
}
</script>

import * as t from "io-ts"

interface ProjectNameBrand {
  readonly ProjectName: unique symbol
}

export const ProjectNameCodec = t.brand(
  t.string,
  (x): x is t.Branded<string, ProjectNameBrand> => x.trim().length >= 6,
  "ProjectName"
)

export type ProjectName = t.TypeOf<typeof ProjectNameCodec>

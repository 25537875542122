/* eslint-disable */ // Auto-generated file (DO NOT EDIT!!!), refer gql-codegen.yml

import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver, StorageAdapter as GraphCacheStorageAdapter } from '@urql/exchange-graphcache';
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddUserToServiceViaEmailInput = {
  email: Scalars['ID'];
  role: ServiceMemberRole;
};

export type Admin = {
  __typename?: 'Admin';
  admins: Array<User>;
  allProjects: Array<Project>;
  allProjectsOptions: Array<ProjectOption>;
  allTeams: Array<ServiceInfo>;
  allUserGroups: Array<UserGroupInfo>;
  allUsers: Array<OrganizationUser>;
  collectionCountInTeam: Scalars['Int'];
  environmentCountInTeam: Scalars['Int'];
  invitedUsers: Array<InvitedUser>;
  listAllUsersOfGroupByAdmin: Array<UserGroupUserInfo>;
  membersCountInTeam: Scalars['Int'];
  projectsCount: Scalars['Int'];
  requestCountInTeam: Scalars['Int'];
  teamCollectionsCount: Scalars['Int'];
  teamInfo: Service;
  teamRequestsCount: Scalars['Int'];
  teamsCount: Scalars['Int'];
  userInfo: OrganizationUser;
  usersCount: Scalars['Int'];
};


export type AdminAllProjectsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AdminAllTeamsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
  projectID?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AdminAllUserGroupsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AdminAllUsersArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type AdminCollectionCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminEnvironmentCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminListAllUsersOfGroupByAdminArgs = {
  groupID: Scalars['ID'];
};


export type AdminMembersCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminRequestCountInTeamArgs = {
  teamID: Scalars['ID'];
};


export type AdminTeamInfoArgs = {
  teamID: Scalars['ID'];
};


export type AdminUserInfoArgs = {
  userUid: Scalars['ID'];
};

export type AdminOrganization = {
  __typename?: 'AdminOrganization';
  orgID: Scalars['String'];
  orgName: Scalars['String'];
  role: Scalars['String'];
};

export type CreateServiceRequestInput = {
  request: Scalars['String'];
  serviceID: Scalars['ID'];
  title: Scalars['String'];
};

export type InvitedUser = {
  __typename?: 'InvitedUser';
  adminEmail: Scalars['String'];
  adminUid: Scalars['ID'];
  invitedOn: Scalars['DateTime'];
  inviteeEmail: Scalars['String'];
};

export type ModuleReorderData = {
  __typename?: 'ModuleReorderData';
  collection: ServiceModule;
  nextCollection?: Maybe<ServiceModule>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManyUsersToServiceViaEmail: Scalars['Boolean'];
  addOneUserToServiceViaEmail: ServiceMember;
  addUserToGroupByAdmin: UserGroupUserInfo;
  addUserToTeamByAdmin: ServiceMember;
  changeUserRoleInTeamByAdmin: ServiceMember;
  clearGlobalEnvironments: UserEnvironment;
  createChildModule: ServiceModule;
  createDuplicateEnvironment: ServiceEnvironment;
  createGQLChildUserCollection: UserCollection;
  createGQLRootUserCollection: UserCollection;
  createGQLUserRequest: UserRequest;
  createOrganizationUserByAdmin: OrganizationUser;
  createProject: Project;
  createRESTChildUserCollection: UserCollection;
  createRESTRootUserCollection: UserCollection;
  createRESTUserRequest: UserRequest;
  createRequestInModule: ServiceRequest;
  createRootModule: ServiceModule;
  createService: Service;
  createServiceEnvironment: ServiceEnvironment;
  createShortcode: Shortcode;
  createTeamByAdmin: Service;
  createUserEnvironment: UserEnvironment;
  createUserGlobalEnvironment: UserEnvironment;
  createUserGroupByAdmin: UserGroup;
  createUserHistory: UserHistory;
  createUserSettings: UserSettings;
  deleteAllUserHistory: UserHistoryDeletedManyData;
  deleteAllVariablesFromTeamEnvironment: ServiceEnvironment;
  deleteModule: Scalars['Boolean'];
  deleteProject: Scalars['Boolean'];
  deleteRequest: Scalars['Boolean'];
  deleteService: Scalars['Boolean'];
  deleteTeamByAdmin: Scalars['Boolean'];
  deleteTeamEnvironment: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUserCollection: Scalars['Boolean'];
  deleteUserEnvironment: Scalars['Boolean'];
  deleteUserEnvironments: Scalars['Int'];
  deleteUserGroupByAdmin: Scalars['Boolean'];
  deleteUserRequest: Scalars['Boolean'];
  importModulesFromJSON: Scalars['Boolean'];
  importUserCollectionsFromJSON: Scalars['Boolean'];
  inviteNewUser: InvitedUser;
  leaveService: Scalars['Boolean'];
  makeUserAdmin: Scalars['Boolean'];
  moveModule: ServiceModule;
  moveRequest: ServiceRequest;
  moveUserCollection: UserCollection;
  moveUserRequest: UserRequest;
  removeRequestFromHistory: UserHistory;
  removeServiceMember: Scalars['Boolean'];
  removeUserAsAdmin: Scalars['Boolean'];
  removeUserByAdmin: Scalars['Boolean'];
  removeUserFromGroupByAdmin: Scalars['Boolean'];
  removeUserFromTeamByAdmin: Scalars['Boolean'];
  renameModule: ServiceModule;
  renameProject: Project;
  renameService: Service;
  renameTeamByAdmin: Service;
  renameUserCollection: UserCollection;
  renameUserGroupByAdmin: Scalars['Boolean'];
  replaceCollectionsWithJSON: Scalars['Boolean'];
  revokeShortcode: Scalars['Boolean'];
  toggleHistoryStarStatus: UserHistory;
  updateGQLUserRequest: UserRequest;
  updateLookUpRequestOrder: Scalars['Boolean'];
  updateModuleOrder: Scalars['Boolean'];
  updateOrganizationUserByAdmin: OrganizationUser;
  updateRESTUserRequest: UserRequest;
  updateRequest: ServiceRequest;
  updateServiceEnvironment: ServiceEnvironment;
  updateServiceMemberRole: ServiceMember;
  updateUserCollectionOrder: Scalars['Boolean'];
  updateUserEnvironment: UserEnvironment;
  updateUserSessions: User;
  updateUserSettings: UserSettings;
};


export type MutationAddManyUsersToServiceViaEmailArgs = {
  newMembersInput: Array<AddUserToServiceViaEmailInput>;
  serviceID: Scalars['ID'];
};


export type MutationAddOneUserToServiceViaEmailArgs = {
  email: Scalars['ID'];
  role: ServiceMemberRole;
  serviceID: Scalars['ID'];
};


export type MutationAddUserToGroupByAdminArgs = {
  groupID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationAddUserToTeamByAdminArgs = {
  role: ServiceMemberRole;
  teamID: Scalars['ID'];
  userEmail: Scalars['String'];
};


export type MutationChangeUserRoleInTeamByAdminArgs = {
  newRole: ServiceMemberRole;
  teamID: Scalars['ID'];
  userUID: Scalars['ID'];
};


export type MutationClearGlobalEnvironmentsArgs = {
  id: Scalars['ID'];
};


export type MutationCreateChildModuleArgs = {
  childTitle: Scalars['String'];
  moduleID: Scalars['ID'];
};


export type MutationCreateDuplicateEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationCreateGqlChildUserCollectionArgs = {
  parentUserCollectionID: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateGqlRootUserCollectionArgs = {
  title: Scalars['String'];
};


export type MutationCreateGqlUserRequestArgs = {
  collectionID: Scalars['ID'];
  request: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateOrganizationUserByAdminArgs = {
  fullName: Scalars['String'];
  role: OrganizationUserRole;
  userEmail: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  name: Scalars['String'];
  orgID: Scalars['ID'];
};


export type MutationCreateRestChildUserCollectionArgs = {
  parentUserCollectionID: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateRestRootUserCollectionArgs = {
  title: Scalars['String'];
};


export type MutationCreateRestUserRequestArgs = {
  collectionID: Scalars['ID'];
  request: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreateRequestInModuleArgs = {
  data: CreateServiceRequestInput;
  moduleID: Scalars['ID'];
};


export type MutationCreateRootModuleArgs = {
  serviceID: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateServiceArgs = {
  name: Scalars['String'];
  projectID: Scalars['ID'];
};


export type MutationCreateServiceEnvironmentArgs = {
  name: Scalars['String'];
  serviceID: Scalars['ID'];
  variables: Scalars['String'];
};


export type MutationCreateShortcodeArgs = {
  request: Scalars['String'];
};


export type MutationCreateTeamByAdminArgs = {
  name: Scalars['String'];
  projectID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationCreateUserEnvironmentArgs = {
  name: Scalars['String'];
  variables: Scalars['String'];
};


export type MutationCreateUserGlobalEnvironmentArgs = {
  variables: Scalars['String'];
};


export type MutationCreateUserGroupByAdminArgs = {
  groupName: Scalars['String'];
};


export type MutationCreateUserHistoryArgs = {
  reqData: Scalars['String'];
  reqType: ReqType;
  resMetadata: Scalars['String'];
};


export type MutationCreateUserSettingsArgs = {
  properties: Scalars['String'];
};


export type MutationDeleteAllUserHistoryArgs = {
  reqType: ReqType;
};


export type MutationDeleteAllVariablesFromTeamEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteModuleArgs = {
  moduleID: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  projectID: Scalars['ID'];
};


export type MutationDeleteRequestArgs = {
  requestID: Scalars['ID'];
};


export type MutationDeleteServiceArgs = {
  serviceID: Scalars['ID'];
};


export type MutationDeleteTeamByAdminArgs = {
  teamID: Scalars['ID'];
};


export type MutationDeleteTeamEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserCollectionArgs = {
  userCollectionID: Scalars['ID'];
};


export type MutationDeleteUserEnvironmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupByAdminArgs = {
  groupID: Scalars['ID'];
};


export type MutationDeleteUserRequestArgs = {
  id: Scalars['ID'];
};


export type MutationImportModulesFromJsonArgs = {
  jsonString: Scalars['String'];
  parentModuleID?: InputMaybe<Scalars['ID']>;
  serviceID: Scalars['ID'];
};


export type MutationImportUserCollectionsFromJsonArgs = {
  jsonString: Scalars['String'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
  reqType: ReqType;
};


export type MutationInviteNewUserArgs = {
  inviteeEmail: Scalars['String'];
};


export type MutationLeaveServiceArgs = {
  serviceID: Scalars['ID'];
};


export type MutationMakeUserAdminArgs = {
  userUid: Scalars['ID'];
};


export type MutationMoveModuleArgs = {
  moduleID: Scalars['ID'];
  parentModuleID?: InputMaybe<Scalars['ID']>;
};


export type MutationMoveRequestArgs = {
  destMolID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  requestID: Scalars['ID'];
  srcMolID?: InputMaybe<Scalars['ID']>;
};


export type MutationMoveUserCollectionArgs = {
  destCollectionID?: InputMaybe<Scalars['ID']>;
  userCollectionID: Scalars['ID'];
};


export type MutationMoveUserRequestArgs = {
  destinationCollectionID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  requestID: Scalars['ID'];
  sourceCollectionID: Scalars['ID'];
};


export type MutationRemoveRequestFromHistoryArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveServiceMemberArgs = {
  serviceID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationRemoveUserAsAdminArgs = {
  userUid: Scalars['ID'];
};


export type MutationRemoveUserByAdminArgs = {
  userUid: Scalars['ID'];
};


export type MutationRemoveUserFromGroupByAdminArgs = {
  groupID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationRemoveUserFromTeamByAdminArgs = {
  teamID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationRenameModuleArgs = {
  moduleID: Scalars['ID'];
  newTitle: Scalars['String'];
};


export type MutationRenameProjectArgs = {
  newName: Scalars['ID'];
  projectID: Scalars['ID'];
};


export type MutationRenameServiceArgs = {
  newName: Scalars['String'];
  serviceID: Scalars['ID'];
};


export type MutationRenameTeamByAdminArgs = {
  newName: Scalars['String'];
  teamID: Scalars['ID'];
};


export type MutationRenameUserCollectionArgs = {
  newTitle: Scalars['String'];
  userCollectionID: Scalars['ID'];
};


export type MutationRenameUserGroupByAdminArgs = {
  groupID: Scalars['ID'];
  groupName: Scalars['String'];
};


export type MutationReplaceCollectionsWithJsonArgs = {
  jsonString: Scalars['String'];
  parentCollectionID?: InputMaybe<Scalars['ID']>;
  serviceID: Scalars['ID'];
};


export type MutationRevokeShortcodeArgs = {
  code: Scalars['ID'];
};


export type MutationToggleHistoryStarStatusArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateGqlUserRequestArgs = {
  id: Scalars['ID'];
  request?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateLookUpRequestOrderArgs = {
  moduleID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
  requestID: Scalars['ID'];
};


export type MutationUpdateModuleOrderArgs = {
  destModID?: InputMaybe<Scalars['ID']>;
  moduleID: Scalars['ID'];
};


export type MutationUpdateOrganizationUserByAdminArgs = {
  newFullName: Scalars['String'];
  newRole: OrganizationUserRole;
  userUid: Scalars['ID'];
};


export type MutationUpdateRestUserRequestArgs = {
  id: Scalars['ID'];
  request?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateRequestArgs = {
  data: UpdateServiceRequestInput;
  requestID: Scalars['ID'];
};


export type MutationUpdateServiceEnvironmentArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  variables: Scalars['String'];
};


export type MutationUpdateServiceMemberRoleArgs = {
  newRole: ServiceMemberRole;
  serviceID: Scalars['ID'];
  userUid: Scalars['ID'];
};


export type MutationUpdateUserCollectionOrderArgs = {
  collectionID: Scalars['ID'];
  nextCollectionID?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateUserEnvironmentArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  variables: Scalars['String'];
};


export type MutationUpdateUserSessionsArgs = {
  currentSession: Scalars['String'];
  sessionType: SessionType;
};


export type MutationUpdateUserSettingsArgs = {
  properties: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  myProjects: Array<Project>;
  myRole?: Maybe<OrganizationUserRole>;
  name: Scalars['String'];
};

export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  authEmail: Scalars['String'];
  createdOn: Scalars['DateTime'];
  fullName: Scalars['String'];
  orgID: Scalars['ID'];
  photoURL?: Maybe<Scalars['String']>;
  role: OrganizationUserRole;
  userUid: Scalars['ID'];
};

export enum OrganizationUserRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  ProjectAdmin = 'PROJECT_ADMIN',
  SuperAdmin = 'SUPER_ADMIN'
}

export type Project = {
  __typename?: 'Project';
  adminUserUid?: Maybe<Scalars['ID']>;
  createdBy: Scalars['ID'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  orgID: Scalars['ID'];
};

export type ProjectOption = {
  __typename?: 'ProjectOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  admin: Admin;
  exportModulesToJSON: Scalars['String'];
  exportUserCollectionsToJSON: UserCollectionExportJsonData;
  getAllOrganizationUsers: Array<OrganizationUser>;
  me: User;
  myProjectServices: Array<Service>;
  myShortcodes: Array<Shortcode>;
  organization: Organization;
  project: Project;
  request?: Maybe<ServiceRequest>;
  requestsInModule: Array<ServiceRequest>;
  rootGQLUserCollections: Array<UserCollection>;
  rootModulesOfService: Array<ServiceModule>;
  rootRESTUserCollections: Array<UserCollection>;
  searchForRequest: Array<ServiceRequest>;
  service?: Maybe<Service>;
  serviceDetailInfo?: Maybe<ServiceDetailInfo>;
  serviceModule?: Maybe<ServiceModule>;
  shortcode?: Maybe<Shortcode>;
  userCollection: UserCollection;
  userGQLRequests: Array<UserRequest>;
  userRESTRequests: Array<UserRequest>;
  userRequest: UserRequest;
};


export type QueryExportModulesToJsonArgs = {
  serviceID: Scalars['ID'];
};


export type QueryExportUserCollectionsToJsonArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  collectionType: ReqType;
};


export type QueryMyProjectServicesArgs = {
  projectID: Scalars['ID'];
};


export type QueryMyShortcodesArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  orgID: Scalars['ID'];
};


export type QueryProjectArgs = {
  projectID: Scalars['ID'];
};


export type QueryRequestArgs = {
  requestID: Scalars['ID'];
};


export type QueryRequestsInModuleArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  moduleID: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryRootGqlUserCollectionsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryRootModulesOfServiceArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  serviceID: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryRootRestUserCollectionsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchForRequestArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  searchTerm: Scalars['String'];
  serviceID: Scalars['ID'];
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryServiceArgs = {
  serviceID: Scalars['ID'];
};


export type QueryServiceDetailInfoArgs = {
  serviceID: Scalars['ID'];
};


export type QueryServiceModuleArgs = {
  moduleID: Scalars['ID'];
};


export type QueryShortcodeArgs = {
  code: Scalars['ID'];
};


export type QueryUserCollectionArgs = {
  userCollectionID: Scalars['ID'];
};


export type QueryUserGqlRequestsArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUserRestRequestsArgs = {
  collectionID?: InputMaybe<Scalars['ID']>;
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUserRequestArgs = {
  id: Scalars['ID'];
};

export enum ReqType {
  Gql = 'GQL',
  Rest = 'REST'
}

export type RequestReorderData = {
  __typename?: 'RequestReorderData';
  nextRequest?: Maybe<ServiceRequest>;
  request: ServiceRequest;
};

export type Service = {
  __typename?: 'Service';
  editorsCount: Scalars['Int'];
  id: Scalars['ID'];
  members: Array<ServiceMember>;
  myRole?: Maybe<ServiceMemberRole>;
  name: Scalars['String'];
  orgID: Scalars['ID'];
  ownersCount: Scalars['Int'];
  projectID: Scalars['ID'];
  serviceMembers: Array<ServiceMember>;
  teamEnvironments: Array<ServiceEnvironment>;
  viewersCount: Scalars['Int'];
};


export type ServiceMembersArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
};

export type ServiceDetailInfo = {
  __typename?: 'ServiceDetailInfo';
  editorsCount: Scalars['Int'];
  id: Scalars['ID'];
  members: Array<ServiceMemberInfo>;
  myRole?: Maybe<ServiceMemberRole>;
  name: Scalars['String'];
  orgID: Scalars['ID'];
  ownersCount: Scalars['Int'];
  projectID: Scalars['ID'];
  projectName: Scalars['String'];
  serviceMembers: Array<ServiceMember>;
  teamEnvironments: Array<ServiceEnvironment>;
  viewersCount: Scalars['Int'];
};

export type ServiceEnvironment = {
  __typename?: 'ServiceEnvironment';
  id: Scalars['ID'];
  name: Scalars['String'];
  serviceID: Scalars['ID'];
  variables: Scalars['String'];
};

export type ServiceInfo = {
  __typename?: 'ServiceInfo';
  editorsCount: Scalars['Int'];
  id: Scalars['ID'];
  members: Array<ServiceMember>;
  membersCount: Scalars['Int'];
  myRole?: Maybe<ServiceMemberRole>;
  name: Scalars['String'];
  orgID: Scalars['ID'];
  ownersCount: Scalars['Int'];
  projectID: Scalars['ID'];
  projectName: Scalars['String'];
  serviceMembers: Array<ServiceMember>;
  teamEnvironments: Array<ServiceEnvironment>;
  viewersCount: Scalars['Int'];
};


export type ServiceInfoMembersArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
};

export type ServiceMember = {
  __typename?: 'ServiceMember';
  membershipID: Scalars['ID'];
  orgID: Scalars['ID'];
  projectID: Scalars['ID'];
  role: ServiceMemberRole;
  serviceID: Scalars['ID'];
  user: User;
  userUid: Scalars['ID'];
};

export type ServiceMemberInfo = {
  __typename?: 'ServiceMemberInfo';
  email: Scalars['String'];
  fullName: Scalars['String'];
  role: ServiceMemberRole;
  userUid: Scalars['ID'];
};

export enum ServiceMemberRole {
  Editor = 'EDITOR',
  Exporter = 'EXPORTER',
  Viewer = 'VIEWER'
}

export type ServiceModule = {
  __typename?: 'ServiceModule';
  children: Array<ServiceModule>;
  id: Scalars['ID'];
  parent?: Maybe<ServiceModule>;
  parentID?: Maybe<Scalars['ID']>;
  service: Service;
  title: Scalars['String'];
};


export type ServiceModuleChildrenArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  id: Scalars['ID'];
  module: ServiceModule;
  moduleID: Scalars['ID'];
  request: Scalars['String'];
  service: Service;
  serviceID: Scalars['ID'];
  title: Scalars['String'];
};

export enum SessionType {
  Gql = 'GQL',
  Rest = 'REST'
}

export type Shortcode = {
  __typename?: 'Shortcode';
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  request: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  moduleOrderUpdated: ModuleReorderData;
  myShortcodesCreated: Shortcode;
  myShortcodesRevoked: Shortcode;
  requestMoved: ServiceRequest;
  requestOrderUpdated: RequestReorderData;
  serviceEnvironmentCreated: ServiceEnvironment;
  serviceEnvironmentDeleted: ServiceEnvironment;
  serviceEnvironmentUpdated: ServiceEnvironment;
  serviceMemberAdded: ServiceMember;
  serviceMemberRemoved: Scalars['ID'];
  serviceMemberUpdated: ServiceMember;
  serviceModuleAdded: ServiceModule;
  serviceModuleMoved: ServiceModule;
  serviceModuleRemoved: Scalars['ID'];
  serviceModuleUpdated: ServiceModule;
  serviceRequestAdded: ServiceRequest;
  serviceRequestDeleted: Scalars['ID'];
  serviceRequestUpdated: ServiceRequest;
  userCollectionCreated: UserCollection;
  userCollectionMoved: UserCollection;
  userCollectionOrderUpdated: UserCollectionReorderData;
  userCollectionRemoved: UserCollectionRemovedData;
  userCollectionUpdated: UserCollection;
  userDeleted: User;
  userEnvironmentCreated: UserEnvironment;
  userEnvironmentDeleteMany: Scalars['Int'];
  userEnvironmentDeleted: UserEnvironment;
  userEnvironmentUpdated: UserEnvironment;
  userHistoryCreated: UserHistory;
  userHistoryDeleted: UserHistory;
  userHistoryDeletedMany: UserHistoryDeletedManyData;
  userHistoryUpdated: UserHistory;
  userInvited: InvitedUser;
  userRequestCreated: UserRequest;
  userRequestDeleted: UserRequest;
  userRequestMoved: UserRequestReorderData;
  userRequestUpdated: UserRequest;
  userSettingsCreated: UserSettings;
  userSettingsUpdated: UserSettings;
  userUpdated: User;
};


export type SubscriptionModuleOrderUpdatedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionRequestMovedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionRequestOrderUpdatedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceEnvironmentCreatedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceEnvironmentDeletedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceEnvironmentUpdatedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceMemberAddedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceMemberRemovedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceMemberUpdatedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceModuleAddedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceModuleMovedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceModuleRemovedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceModuleUpdatedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceRequestAddedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceRequestDeletedArgs = {
  serviceID: Scalars['ID'];
};


export type SubscriptionServiceRequestUpdatedArgs = {
  serviceID: Scalars['ID'];
};

export type UpdateServiceRequestInput = {
  request?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  GQLHistory: Array<UserHistory>;
  RESTHistory: Array<UserHistory>;
  adminOrganizations: Array<AdminOrganization>;
  createdOn: Scalars['DateTime'];
  currentGQLSession?: Maybe<Scalars['String']>;
  currentRESTSession?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  environments: Array<UserEnvironment>;
  globalEnvironments: UserEnvironment;
  photoURL?: Maybe<Scalars['String']>;
  settings: UserSettings;
  uid: Scalars['ID'];
};


export type UserGqlHistoryArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserRestHistoryArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UserCollection = {
  __typename?: 'UserCollection';
  childrenGQL: Array<UserCollection>;
  childrenREST: Array<UserCollection>;
  id: Scalars['ID'];
  parent?: Maybe<UserCollection>;
  requests: Array<UserRequest>;
  title: Scalars['String'];
  type: ReqType;
  user: User;
};


export type UserCollectionChildrenGqlArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserCollectionChildrenRestArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserCollectionRequestsArgs = {
  cursor?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UserCollectionExportJsonData = {
  __typename?: 'UserCollectionExportJSONData';
  collectionType: ReqType;
  exportedCollection: Scalars['ID'];
};

export type UserCollectionRemovedData = {
  __typename?: 'UserCollectionRemovedData';
  id: Scalars['ID'];
  type: ReqType;
};

export type UserCollectionReorderData = {
  __typename?: 'UserCollectionReorderData';
  nextUserCollection?: Maybe<UserCollection>;
  userCollection: UserCollection;
};

export type UserEnvironment = {
  __typename?: 'UserEnvironment';
  id: Scalars['ID'];
  isGlobal: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  userUid: Scalars['ID'];
  variables: Scalars['String'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orgID: Scalars['ID'];
};

export type UserGroupInfo = {
  __typename?: 'UserGroupInfo';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orgID: Scalars['ID'];
  userCount: Scalars['Int'];
};

export type UserGroupUserInfo = {
  __typename?: 'UserGroupUserInfo';
  authEmail: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  fullName: Scalars['String'];
  groupID: Scalars['ID'];
  orgID: Scalars['ID'];
  userUid: Scalars['ID'];
};

export type UserHistory = {
  __typename?: 'UserHistory';
  executedOn: Scalars['DateTime'];
  id: Scalars['ID'];
  isStarred: Scalars['Boolean'];
  reqType: ReqType;
  request: Scalars['String'];
  responseMetadata: Scalars['String'];
  userUid: Scalars['ID'];
};

export type UserHistoryDeletedManyData = {
  __typename?: 'UserHistoryDeletedManyData';
  count: Scalars['Int'];
  reqType: ReqType;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
};

export type UserRequest = {
  __typename?: 'UserRequest';
  collectionID: Scalars['ID'];
  createdOn: Scalars['DateTime'];
  id: Scalars['ID'];
  request: Scalars['String'];
  title: Scalars['String'];
  type: ReqType;
  user: User;
};

export type UserRequestReorderData = {
  __typename?: 'UserRequestReorderData';
  nextRequest?: Maybe<UserRequest>;
  request: UserRequest;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  id: Scalars['ID'];
  properties: Scalars['String'];
  updatedOn: Scalars['DateTime'];
  userUid: Scalars['ID'];
};

export type ClearGlobalEnvironmentsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ClearGlobalEnvironmentsMutation = { __typename?: 'Mutation', clearGlobalEnvironments: { __typename?: 'UserEnvironment', id: string } };

export type CreateGqlChildUserCollectionMutationVariables = Exact<{
  title: Scalars['String'];
  parentUserCollectionID: Scalars['ID'];
}>;


export type CreateGqlChildUserCollectionMutation = { __typename?: 'Mutation', createGQLChildUserCollection: { __typename?: 'UserCollection', id: string } };

export type CreateGqlRootUserCollectionMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateGqlRootUserCollectionMutation = { __typename?: 'Mutation', createGQLRootUserCollection: { __typename?: 'UserCollection', id: string } };

export type CreateGqlUserRequestMutationVariables = Exact<{
  title: Scalars['String'];
  request: Scalars['String'];
  collectionID: Scalars['ID'];
}>;


export type CreateGqlUserRequestMutation = { __typename?: 'Mutation', createGQLUserRequest: { __typename?: 'UserRequest', id: string } };

export type CreateRestChildUserCollectionMutationVariables = Exact<{
  title: Scalars['String'];
  parentUserCollectionID: Scalars['ID'];
}>;


export type CreateRestChildUserCollectionMutation = { __typename?: 'Mutation', createRESTChildUserCollection: { __typename?: 'UserCollection', id: string } };

export type CreateRestRootUserCollectionMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateRestRootUserCollectionMutation = { __typename?: 'Mutation', createRESTRootUserCollection: { __typename?: 'UserCollection', id: string } };

export type CreateRestUserRequestMutationVariables = Exact<{
  collectionID: Scalars['ID'];
  title: Scalars['String'];
  request: Scalars['String'];
}>;


export type CreateRestUserRequestMutation = { __typename?: 'Mutation', createRESTUserRequest: { __typename?: 'UserRequest', id: string } };

export type CreateUserEnvironmentMutationVariables = Exact<{
  name: Scalars['String'];
  variables: Scalars['String'];
}>;


export type CreateUserEnvironmentMutation = { __typename?: 'Mutation', createUserEnvironment: { __typename?: 'UserEnvironment', id: string, userUid: string, name?: string | null, variables: string, isGlobal: boolean } };

export type CreateUserGlobalEnvironmentMutationVariables = Exact<{
  variables: Scalars['String'];
}>;


export type CreateUserGlobalEnvironmentMutation = { __typename?: 'Mutation', createUserGlobalEnvironment: { __typename?: 'UserEnvironment', id: string } };

export type CreateUserHistoryMutationVariables = Exact<{
  reqData: Scalars['String'];
  resMetadata: Scalars['String'];
  reqType: ReqType;
}>;


export type CreateUserHistoryMutation = { __typename?: 'Mutation', createUserHistory: { __typename?: 'UserHistory', id: string } };

export type CreateUserSettingsMutationVariables = Exact<{
  properties: Scalars['String'];
}>;


export type CreateUserSettingsMutation = { __typename?: 'Mutation', createUserSettings: { __typename?: 'UserSettings', id: string } };

export type DeleteAllUserHistoryMutationVariables = Exact<{
  reqType: ReqType;
}>;


export type DeleteAllUserHistoryMutation = { __typename?: 'Mutation', deleteAllUserHistory: { __typename?: 'UserHistoryDeletedManyData', count: number, reqType: ReqType } };

export type DeleteUserCollectionMutationVariables = Exact<{
  userCollectionID: Scalars['ID'];
}>;


export type DeleteUserCollectionMutation = { __typename?: 'Mutation', deleteUserCollection: boolean };

export type DeleteUserEnvironmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserEnvironmentMutation = { __typename?: 'Mutation', deleteUserEnvironment: boolean };

export type DeleteUserRequestMutationVariables = Exact<{
  requestID: Scalars['ID'];
}>;


export type DeleteUserRequestMutation = { __typename?: 'Mutation', deleteUserRequest: boolean };

export type MoveUserCollectionMutationVariables = Exact<{
  destCollectionID?: InputMaybe<Scalars['ID']>;
  userCollectionID: Scalars['ID'];
}>;


export type MoveUserCollectionMutation = { __typename?: 'Mutation', moveUserCollection: { __typename?: 'UserCollection', id: string } };

export type MoveUserRequestMutationVariables = Exact<{
  sourceCollectionID: Scalars['ID'];
  requestID: Scalars['ID'];
  destinationCollectionID: Scalars['ID'];
  nextRequestID?: InputMaybe<Scalars['ID']>;
}>;


export type MoveUserRequestMutation = { __typename?: 'Mutation', moveUserRequest: { __typename?: 'UserRequest', id: string } };

export type RemoveRequestFromHistoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveRequestFromHistoryMutation = { __typename?: 'Mutation', removeRequestFromHistory: { __typename?: 'UserHistory', id: string } };

export type RenameUserCollectionMutationVariables = Exact<{
  userCollectionID: Scalars['ID'];
  newTitle: Scalars['String'];
}>;


export type RenameUserCollectionMutation = { __typename?: 'Mutation', renameUserCollection: { __typename?: 'UserCollection', id: string } };

export type ToggleHistoryStarStatusMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleHistoryStarStatusMutation = { __typename?: 'Mutation', toggleHistoryStarStatus: { __typename?: 'UserHistory', id: string } };

export type UpdateGqlUserRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  request: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpdateGqlUserRequestMutation = { __typename?: 'Mutation', updateGQLUserRequest: { __typename?: 'UserRequest', id: string } };

export type UpdateRestUserRequestMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  request: Scalars['String'];
}>;


export type UpdateRestUserRequestMutation = { __typename?: 'Mutation', updateRESTUserRequest: { __typename?: 'UserRequest', id: string, collectionID: string, request: string } };

export type UpdateUserCollectionOrderMutationVariables = Exact<{
  collectionID: Scalars['ID'];
  nextCollectionID?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateUserCollectionOrderMutation = { __typename?: 'Mutation', updateUserCollectionOrder: boolean };

export type UpdateUserEnvironmentMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  variables: Scalars['String'];
}>;


export type UpdateUserEnvironmentMutation = { __typename?: 'Mutation', updateUserEnvironment: { __typename?: 'UserEnvironment', id: string, userUid: string, name?: string | null, variables: string, isGlobal: boolean } };

export type UpdateUserSessionMutationVariables = Exact<{
  currentSession: Scalars['String'];
  sessionType: SessionType;
}>;


export type UpdateUserSessionMutation = { __typename?: 'Mutation', updateUserSessions: { __typename?: 'User', currentRESTSession?: string | null } };

export type UpdateUserSettingsMutationVariables = Exact<{
  properties: Scalars['String'];
}>;


export type UpdateUserSettingsMutation = { __typename?: 'Mutation', updateUserSettings: { __typename?: 'UserSettings', id: string } };

export type ExportUserCollectionsToJsonQueryVariables = Exact<{
  collectionID?: InputMaybe<Scalars['ID']>;
  collectionType: ReqType;
}>;


export type ExportUserCollectionsToJsonQuery = { __typename?: 'Query', exportUserCollectionsToJSON: { __typename?: 'UserCollectionExportJSONData', collectionType: ReqType, exportedCollection: string } };

export type GetCurrentRestSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentRestSessionQuery = { __typename?: 'Query', me: { __typename?: 'User', currentRESTSession?: string | null } };

export type GetGlobalEnvironmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalEnvironmentsQuery = { __typename?: 'Query', me: { __typename?: 'User', globalEnvironments: { __typename?: 'UserEnvironment', id: string, isGlobal: boolean, name?: string | null, userUid: string, variables: string } } };

export type GetRestUserHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRestUserHistoryQuery = { __typename?: 'Query', me: { __typename?: 'User', RESTHistory: Array<{ __typename?: 'UserHistory', id: string, userUid: string, reqType: ReqType, request: string, responseMetadata: string, isStarred: boolean, executedOn: any }>, GQLHistory: Array<{ __typename?: 'UserHistory', id: string, userUid: string, reqType: ReqType, request: string, responseMetadata: string, isStarred: boolean, executedOn: any }> } };

export type GetGqlRootUserCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGqlRootUserCollectionsQuery = { __typename?: 'Query', rootGQLUserCollections: Array<{ __typename?: 'UserCollection', id: string, title: string, type: ReqType, childrenGQL: Array<{ __typename?: 'UserCollection', id: string, title: string, type: ReqType }> }> };

export type GetUserEnvironmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserEnvironmentsQuery = { __typename?: 'Query', me: { __typename?: 'User', environments: Array<{ __typename?: 'UserEnvironment', id: string, isGlobal: boolean, name?: string | null, userUid: string, variables: string }> } };

export type GetUserRootCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRootCollectionsQuery = { __typename?: 'Query', rootRESTUserCollections: Array<{ __typename?: 'UserCollection', id: string, title: string, type: ReqType, childrenREST: Array<{ __typename?: 'UserCollection', id: string, title: string, type: ReqType }> }> };

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSettingsQuery = { __typename?: 'Query', me: { __typename?: 'User', settings: { __typename?: 'UserSettings', id: string, properties: string } } };

export type UserCollectionCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserCollectionCreatedSubscription = { __typename?: 'Subscription', userCollectionCreated: { __typename?: 'UserCollection', id: string, title: string, type: ReqType, parent?: { __typename?: 'UserCollection', id: string } | null } };

export type UserCollectionMovedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserCollectionMovedSubscription = { __typename?: 'Subscription', userCollectionMoved: { __typename?: 'UserCollection', id: string, type: ReqType, parent?: { __typename?: 'UserCollection', id: string } | null } };

export type UserCollectionOrderUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserCollectionOrderUpdatedSubscription = { __typename?: 'Subscription', userCollectionOrderUpdated: { __typename?: 'UserCollectionReorderData', userCollection: { __typename?: 'UserCollection', id: string, parent?: { __typename?: 'UserCollection', id: string } | null }, nextUserCollection?: { __typename?: 'UserCollection', id: string, parent?: { __typename?: 'UserCollection', id: string } | null } | null } };

export type UserCollectionRemovedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserCollectionRemovedSubscription = { __typename?: 'Subscription', userCollectionRemoved: { __typename?: 'UserCollectionRemovedData', id: string, type: ReqType } };

export type UserCollectionUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserCollectionUpdatedSubscription = { __typename?: 'Subscription', userCollectionUpdated: { __typename?: 'UserCollection', id: string, title: string, type: ReqType, parent?: { __typename?: 'UserCollection', id: string } | null } };

export type UserEnvironmentCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserEnvironmentCreatedSubscription = { __typename?: 'Subscription', userEnvironmentCreated: { __typename?: 'UserEnvironment', id: string, isGlobal: boolean, name?: string | null, userUid: string, variables: string } };

export type UserEnvironmentDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserEnvironmentDeletedSubscription = { __typename?: 'Subscription', userEnvironmentDeleted: { __typename?: 'UserEnvironment', id: string } };

export type UserEnvironmentUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserEnvironmentUpdatedSubscription = { __typename?: 'Subscription', userEnvironmentUpdated: { __typename?: 'UserEnvironment', id: string, userUid: string, name?: string | null, variables: string, isGlobal: boolean } };

export type UserHistoryCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserHistoryCreatedSubscription = { __typename?: 'Subscription', userHistoryCreated: { __typename?: 'UserHistory', id: string, reqType: ReqType, request: string, responseMetadata: string, isStarred: boolean, executedOn: any } };

export type UserHistoryDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserHistoryDeletedSubscription = { __typename?: 'Subscription', userHistoryDeleted: { __typename?: 'UserHistory', id: string, reqType: ReqType } };

export type UserHistoryDeletedManySubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserHistoryDeletedManySubscription = { __typename?: 'Subscription', userHistoryDeletedMany: { __typename?: 'UserHistoryDeletedManyData', count: number, reqType: ReqType } };

export type UserHistoryUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserHistoryUpdatedSubscription = { __typename?: 'Subscription', userHistoryUpdated: { __typename?: 'UserHistory', id: string, reqType: ReqType, request: string, responseMetadata: string, isStarred: boolean, executedOn: any } };

export type UserRequestCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserRequestCreatedSubscription = { __typename?: 'Subscription', userRequestCreated: { __typename?: 'UserRequest', id: string, collectionID: string, title: string, request: string, type: ReqType } };

export type UserRequestDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserRequestDeletedSubscription = { __typename?: 'Subscription', userRequestDeleted: { __typename?: 'UserRequest', id: string, collectionID: string, title: string, request: string, type: ReqType } };

export type UserRequestMovedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserRequestMovedSubscription = { __typename?: 'Subscription', userRequestMoved: { __typename?: 'UserRequestReorderData', request: { __typename?: 'UserRequest', id: string, collectionID: string, type: ReqType }, nextRequest?: { __typename?: 'UserRequest', id: string, collectionID: string } | null } };

export type UserRequestUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserRequestUpdatedSubscription = { __typename?: 'Subscription', userRequestUpdated: { __typename?: 'UserRequest', id: string, collectionID: string, title: string, request: string, type: ReqType } };

export type UserSettingsUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserSettingsUpdatedSubscription = { __typename?: 'Subscription', userSettingsUpdated: { __typename?: 'UserSettings', id: string, properties: string } };


export const ClearGlobalEnvironmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ClearGlobalEnvironments"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clearGlobalEnvironments"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ClearGlobalEnvironmentsMutation, ClearGlobalEnvironmentsMutationVariables>;
export const CreateGqlChildUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateGQLChildUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentUserCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createGQLChildUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"parentUserCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentUserCollectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateGqlChildUserCollectionMutation, CreateGqlChildUserCollectionMutationVariables>;
export const CreateGqlRootUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateGQLRootUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createGQLRootUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateGqlRootUserCollectionMutation, CreateGqlRootUserCollectionMutationVariables>;
export const CreateGqlUserRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateGQLUserRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createGQLUserRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateGqlUserRequestMutation, CreateGqlUserRequestMutationVariables>;
export const CreateRestChildUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateRESTChildUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentUserCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRESTChildUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"parentUserCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentUserCollectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateRestChildUserCollectionMutation, CreateRestChildUserCollectionMutationVariables>;
export const CreateRestRootUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateRESTRootUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRESTRootUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateRestRootUserCollectionMutation, CreateRestRootUserCollectionMutationVariables>;
export const CreateRestUserRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateRESTUserRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createRESTUserRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateRestUserRequestMutation, CreateRestUserRequestMutationVariables>;
export const CreateUserEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"isGlobal"}}]}}]}}]} as unknown as DocumentNode<CreateUserEnvironmentMutation, CreateUserEnvironmentMutationVariables>;
export const CreateUserGlobalEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserGlobalEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserGlobalEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateUserGlobalEnvironmentMutation, CreateUserGlobalEnvironmentMutationVariables>;
export const CreateUserHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reqData"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"resMetadata"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reqType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReqType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"reqData"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reqData"}}},{"kind":"Argument","name":{"kind":"Name","value":"resMetadata"},"value":{"kind":"Variable","name":{"kind":"Name","value":"resMetadata"}}},{"kind":"Argument","name":{"kind":"Name","value":"reqType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reqType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateUserHistoryMutation, CreateUserHistoryMutationVariables>;
export const CreateUserSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateUserSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"properties"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"properties"},"value":{"kind":"Variable","name":{"kind":"Name","value":"properties"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CreateUserSettingsMutation, CreateUserSettingsMutationVariables>;
export const DeleteAllUserHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteAllUserHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reqType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReqType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteAllUserHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"reqType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reqType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}}]}}]}}]} as unknown as DocumentNode<DeleteAllUserHistoryMutation, DeleteAllUserHistoryMutationVariables>;
export const DeleteUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userCollectionID"}}}]}]}}]} as unknown as DocumentNode<DeleteUserCollectionMutation, DeleteUserCollectionMutationVariables>;
export const DeleteUserEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUserEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUserEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<DeleteUserEnvironmentMutation, DeleteUserEnvironmentMutationVariables>;
export const DeleteUserRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteUserRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteUserRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}}]}]}}]} as unknown as DocumentNode<DeleteUserRequestMutation, DeleteUserRequestMutationVariables>;
export const MoveUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"destCollectionID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"destCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"destCollectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"userCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userCollectionID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MoveUserCollectionMutation, MoveUserCollectionMutationVariables>;
export const MoveUserRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"MoveUserRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sourceCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"destinationCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextRequestID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveUserRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sourceCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sourceCollectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"requestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"requestID"}}},{"kind":"Argument","name":{"kind":"Name","value":"destinationCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"destinationCollectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextRequestID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextRequestID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<MoveUserRequestMutation, MoveUserRequestMutationVariables>;
export const RemoveRequestFromHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveRequestFromHistory"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeRequestFromHistory"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<RemoveRequestFromHistoryMutation, RemoveRequestFromHistoryMutationVariables>;
export const RenameUserCollectionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RenameUserCollection"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userCollectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"renameUserCollection"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userCollectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"newTitle"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newTitle"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<RenameUserCollectionMutation, RenameUserCollectionMutationVariables>;
export const ToggleHistoryStarStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ToggleHistoryStarStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"toggleHistoryStarStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ToggleHistoryStarStatusMutation, ToggleHistoryStarStatusMutationVariables>;
export const UpdateGqlUserRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateGQLUserRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateGQLUserRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}},{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateGqlUserRequestMutation, UpdateGqlUserRequestMutationVariables>;
export const UpdateRestUserRequestDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRESTUserRequest"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"title"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"request"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateRESTUserRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"title"},"value":{"kind":"Variable","name":{"kind":"Name","value":"title"}}},{"kind":"Argument","name":{"kind":"Name","value":"request"},"value":{"kind":"Variable","name":{"kind":"Name","value":"request"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"request"}}]}}]}}]} as unknown as DocumentNode<UpdateRestUserRequestMutation, UpdateRestUserRequestMutationVariables>;
export const UpdateUserCollectionOrderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserCollectionOrder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nextCollectionID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserCollectionOrder"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"nextCollectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nextCollectionID"}}}]}]}}]} as unknown as DocumentNode<UpdateUserCollectionOrderMutation, UpdateUserCollectionOrderMutationVariables>;
export const UpdateUserEnvironmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserEnvironment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"variables"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserEnvironment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"variables"},"value":{"kind":"Variable","name":{"kind":"Name","value":"variables"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"isGlobal"}}]}}]}}]} as unknown as DocumentNode<UpdateUserEnvironmentMutation, UpdateUserEnvironmentMutationVariables>;
export const UpdateUserSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"currentSession"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sessionType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SessionType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserSessions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"currentSession"},"value":{"kind":"Variable","name":{"kind":"Name","value":"currentSession"}}},{"kind":"Argument","name":{"kind":"Name","value":"sessionType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sessionType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentRESTSession"}}]}}]}}]} as unknown as DocumentNode<UpdateUserSessionMutation, UpdateUserSessionMutationVariables>;
export const UpdateUserSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserSettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"properties"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUserSettings"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"properties"},"value":{"kind":"Variable","name":{"kind":"Name","value":"properties"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const ExportUserCollectionsToJsonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ExportUserCollectionsToJSON"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"collectionType"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReqType"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"exportUserCollectionsToJSON"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"collectionID"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionID"}}},{"kind":"Argument","name":{"kind":"Name","value":"collectionType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"collectionType"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionType"}},{"kind":"Field","name":{"kind":"Name","value":"exportedCollection"}}]}}]}}]} as unknown as DocumentNode<ExportUserCollectionsToJsonQuery, ExportUserCollectionsToJsonQueryVariables>;
export const GetCurrentRestSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentRESTSession"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentRESTSession"}}]}}]}}]} as unknown as DocumentNode<GetCurrentRestSessionQuery, GetCurrentRestSessionQueryVariables>;
export const GetGlobalEnvironmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGlobalEnvironments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"globalEnvironments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isGlobal"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]}}]} as unknown as DocumentNode<GetGlobalEnvironmentsQuery, GetGlobalEnvironmentsQueryVariables>;
export const GetRestUserHistoryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetRESTUserHistory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"RESTHistory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"responseMetadata"}},{"kind":"Field","name":{"kind":"Name","value":"isStarred"}},{"kind":"Field","name":{"kind":"Name","value":"executedOn"}}]}},{"kind":"Field","name":{"kind":"Name","value":"GQLHistory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"responseMetadata"}},{"kind":"Field","name":{"kind":"Name","value":"isStarred"}},{"kind":"Field","name":{"kind":"Name","value":"executedOn"}}]}}]}}]}}]} as unknown as DocumentNode<GetRestUserHistoryQuery, GetRestUserHistoryQueryVariables>;
export const GetGqlRootUserCollectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGQLRootUserCollections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rootGQLUserCollections"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"take"},"value":{"kind":"IntValue","value":"99999"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"childrenGQL"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<GetGqlRootUserCollectionsQuery, GetGqlRootUserCollectionsQueryVariables>;
export const GetUserEnvironmentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserEnvironments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"environments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isGlobal"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserEnvironmentsQuery, GetUserEnvironmentsQueryVariables>;
export const GetUserRootCollectionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserRootCollections"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rootRESTUserCollections"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"take"},"value":{"kind":"IntValue","value":"99999"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"childrenREST"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserRootCollectionsQuery, GetUserRootCollectionsQueryVariables>;
export const GetUserSettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUserSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"settings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]}}]} as unknown as DocumentNode<GetUserSettingsQuery, GetUserSettingsQueryVariables>;
export const UserCollectionCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserCollectionCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCollectionCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<UserCollectionCreatedSubscription, UserCollectionCreatedSubscriptionVariables>;
export const UserCollectionMovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserCollectionMoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCollectionMoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<UserCollectionMovedSubscription, UserCollectionMovedSubscriptionVariables>;
export const UserCollectionOrderUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserCollectionOrderUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCollectionOrderUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCollection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextUserCollection"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UserCollectionOrderUpdatedSubscription, UserCollectionOrderUpdatedSubscriptionVariables>;
export const UserCollectionRemovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserCollectionRemoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCollectionRemoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<UserCollectionRemovedSubscription, UserCollectionRemovedSubscriptionVariables>;
export const UserCollectionUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"userCollectionUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userCollectionUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"parent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<UserCollectionUpdatedSubscription, UserCollectionUpdatedSubscriptionVariables>;
export const UserEnvironmentCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserEnvironmentCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userEnvironmentCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isGlobal"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}}]}}]}}]} as unknown as DocumentNode<UserEnvironmentCreatedSubscription, UserEnvironmentCreatedSubscriptionVariables>;
export const UserEnvironmentDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserEnvironmentDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userEnvironmentDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<UserEnvironmentDeletedSubscription, UserEnvironmentDeletedSubscriptionVariables>;
export const UserEnvironmentUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserEnvironmentUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userEnvironmentUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"userUid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"variables"}},{"kind":"Field","name":{"kind":"Name","value":"isGlobal"}}]}}]}}]} as unknown as DocumentNode<UserEnvironmentUpdatedSubscription, UserEnvironmentUpdatedSubscriptionVariables>;
export const UserHistoryCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserHistoryCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userHistoryCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"responseMetadata"}},{"kind":"Field","name":{"kind":"Name","value":"isStarred"}},{"kind":"Field","name":{"kind":"Name","value":"executedOn"}}]}}]}}]} as unknown as DocumentNode<UserHistoryCreatedSubscription, UserHistoryCreatedSubscriptionVariables>;
export const UserHistoryDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"userHistoryDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userHistoryDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}}]}}]}}]} as unknown as DocumentNode<UserHistoryDeletedSubscription, UserHistoryDeletedSubscriptionVariables>;
export const UserHistoryDeletedManyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserHistoryDeletedMany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userHistoryDeletedMany"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}}]}}]}}]} as unknown as DocumentNode<UserHistoryDeletedManySubscription, UserHistoryDeletedManySubscriptionVariables>;
export const UserHistoryUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserHistoryUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userHistoryUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reqType"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"responseMetadata"}},{"kind":"Field","name":{"kind":"Name","value":"isStarred"}},{"kind":"Field","name":{"kind":"Name","value":"executedOn"}}]}}]}}]} as unknown as DocumentNode<UserHistoryUpdatedSubscription, UserHistoryUpdatedSubscriptionVariables>;
export const UserRequestCreatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserRequestCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userRequestCreated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<UserRequestCreatedSubscription, UserRequestCreatedSubscriptionVariables>;
export const UserRequestDeletedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserRequestDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userRequestDeleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<UserRequestDeletedSubscription, UserRequestDeletedSubscriptionVariables>;
export const UserRequestMovedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserRequestMoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userRequestMoved"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}},{"kind":"Field","name":{"kind":"Name","value":"nextRequest"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}}]}}]}}]}}]} as unknown as DocumentNode<UserRequestMovedSubscription, UserRequestMovedSubscriptionVariables>;
export const UserRequestUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserRequestUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userRequestUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"collectionID"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"request"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]} as unknown as DocumentNode<UserRequestUpdatedSubscription, UserRequestUpdatedSubscriptionVariables>;
export const UserSettingsUpdatedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"UserSettingsUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userSettingsUpdated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"properties"}}]}}]}}]} as unknown as DocumentNode<UserSettingsUpdatedSubscription, UserSettingsUpdatedSubscriptionVariables>;
export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  Admin?: (data: WithTypename<Admin>) => null | string,
  AdminOrganization?: (data: WithTypename<AdminOrganization>) => null | string,
  InvitedUser?: (data: WithTypename<InvitedUser>) => null | string,
  ModuleReorderData?: (data: WithTypename<ModuleReorderData>) => null | string,
  Organization?: (data: WithTypename<Organization>) => null | string,
  OrganizationUser?: (data: WithTypename<OrganizationUser>) => null | string,
  Project?: (data: WithTypename<Project>) => null | string,
  ProjectOption?: (data: WithTypename<ProjectOption>) => null | string,
  RequestReorderData?: (data: WithTypename<RequestReorderData>) => null | string,
  Service?: (data: WithTypename<Service>) => null | string,
  ServiceDetailInfo?: (data: WithTypename<ServiceDetailInfo>) => null | string,
  ServiceEnvironment?: (data: WithTypename<ServiceEnvironment>) => null | string,
  ServiceInfo?: (data: WithTypename<ServiceInfo>) => null | string,
  ServiceMember?: (data: WithTypename<ServiceMember>) => null | string,
  ServiceMemberInfo?: (data: WithTypename<ServiceMemberInfo>) => null | string,
  ServiceModule?: (data: WithTypename<ServiceModule>) => null | string,
  ServiceRequest?: (data: WithTypename<ServiceRequest>) => null | string,
  Shortcode?: (data: WithTypename<Shortcode>) => null | string,
  User?: (data: WithTypename<User>) => null | string,
  UserCollection?: (data: WithTypename<UserCollection>) => null | string,
  UserCollectionExportJSONData?: (data: WithTypename<UserCollectionExportJsonData>) => null | string,
  UserCollectionRemovedData?: (data: WithTypename<UserCollectionRemovedData>) => null | string,
  UserCollectionReorderData?: (data: WithTypename<UserCollectionReorderData>) => null | string,
  UserEnvironment?: (data: WithTypename<UserEnvironment>) => null | string,
  UserGroup?: (data: WithTypename<UserGroup>) => null | string,
  UserGroupInfo?: (data: WithTypename<UserGroupInfo>) => null | string,
  UserGroupUserInfo?: (data: WithTypename<UserGroupUserInfo>) => null | string,
  UserHistory?: (data: WithTypename<UserHistory>) => null | string,
  UserHistoryDeletedManyData?: (data: WithTypename<UserHistoryDeletedManyData>) => null | string,
  UserInfo?: (data: WithTypename<UserInfo>) => null | string,
  UserRequest?: (data: WithTypename<UserRequest>) => null | string,
  UserRequestReorderData?: (data: WithTypename<UserRequestReorderData>) => null | string,
  UserSettings?: (data: WithTypename<UserSettings>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    admin?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Admin> | string>,
    exportModulesToJSON?: GraphCacheResolver<WithTypename<Query>, QueryExportModulesToJsonArgs, Scalars['String'] | string>,
    exportUserCollectionsToJSON?: GraphCacheResolver<WithTypename<Query>, QueryExportUserCollectionsToJsonArgs, WithTypename<UserCollectionExportJsonData> | string>,
    getAllOrganizationUsers?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<OrganizationUser> | string>>,
    me?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<User> | string>,
    myProjectServices?: GraphCacheResolver<WithTypename<Query>, QueryMyProjectServicesArgs, Array<WithTypename<Service> | string>>,
    myShortcodes?: GraphCacheResolver<WithTypename<Query>, QueryMyShortcodesArgs, Array<WithTypename<Shortcode> | string>>,
    organization?: GraphCacheResolver<WithTypename<Query>, QueryOrganizationArgs, WithTypename<Organization> | string>,
    project?: GraphCacheResolver<WithTypename<Query>, QueryProjectArgs, WithTypename<Project> | string>,
    request?: GraphCacheResolver<WithTypename<Query>, QueryRequestArgs, WithTypename<ServiceRequest> | string>,
    requestsInModule?: GraphCacheResolver<WithTypename<Query>, QueryRequestsInModuleArgs, Array<WithTypename<ServiceRequest> | string>>,
    rootGQLUserCollections?: GraphCacheResolver<WithTypename<Query>, QueryRootGqlUserCollectionsArgs, Array<WithTypename<UserCollection> | string>>,
    rootModulesOfService?: GraphCacheResolver<WithTypename<Query>, QueryRootModulesOfServiceArgs, Array<WithTypename<ServiceModule> | string>>,
    rootRESTUserCollections?: GraphCacheResolver<WithTypename<Query>, QueryRootRestUserCollectionsArgs, Array<WithTypename<UserCollection> | string>>,
    searchForRequest?: GraphCacheResolver<WithTypename<Query>, QuerySearchForRequestArgs, Array<WithTypename<ServiceRequest> | string>>,
    service?: GraphCacheResolver<WithTypename<Query>, QueryServiceArgs, WithTypename<Service> | string>,
    serviceDetailInfo?: GraphCacheResolver<WithTypename<Query>, QueryServiceDetailInfoArgs, WithTypename<ServiceDetailInfo> | string>,
    serviceModule?: GraphCacheResolver<WithTypename<Query>, QueryServiceModuleArgs, WithTypename<ServiceModule> | string>,
    shortcode?: GraphCacheResolver<WithTypename<Query>, QueryShortcodeArgs, WithTypename<Shortcode> | string>,
    userCollection?: GraphCacheResolver<WithTypename<Query>, QueryUserCollectionArgs, WithTypename<UserCollection> | string>,
    userGQLRequests?: GraphCacheResolver<WithTypename<Query>, QueryUserGqlRequestsArgs, Array<WithTypename<UserRequest> | string>>,
    userRESTRequests?: GraphCacheResolver<WithTypename<Query>, QueryUserRestRequestsArgs, Array<WithTypename<UserRequest> | string>>,
    userRequest?: GraphCacheResolver<WithTypename<Query>, QueryUserRequestArgs, WithTypename<UserRequest> | string>
  },
  Admin?: {
    admins?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Array<WithTypename<User> | string>>,
    allProjects?: GraphCacheResolver<WithTypename<Admin>, AdminAllProjectsArgs, Array<WithTypename<Project> | string>>,
    allProjectsOptions?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Array<WithTypename<ProjectOption> | string>>,
    allTeams?: GraphCacheResolver<WithTypename<Admin>, AdminAllTeamsArgs, Array<WithTypename<ServiceInfo> | string>>,
    allUserGroups?: GraphCacheResolver<WithTypename<Admin>, AdminAllUserGroupsArgs, Array<WithTypename<UserGroupInfo> | string>>,
    allUsers?: GraphCacheResolver<WithTypename<Admin>, AdminAllUsersArgs, Array<WithTypename<OrganizationUser> | string>>,
    collectionCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminCollectionCountInTeamArgs, Scalars['Int'] | string>,
    environmentCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminEnvironmentCountInTeamArgs, Scalars['Int'] | string>,
    invitedUsers?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Array<WithTypename<InvitedUser> | string>>,
    listAllUsersOfGroupByAdmin?: GraphCacheResolver<WithTypename<Admin>, AdminListAllUsersOfGroupByAdminArgs, Array<WithTypename<UserGroupUserInfo> | string>>,
    membersCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminMembersCountInTeamArgs, Scalars['Int'] | string>,
    projectsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    requestCountInTeam?: GraphCacheResolver<WithTypename<Admin>, AdminRequestCountInTeamArgs, Scalars['Int'] | string>,
    teamCollectionsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    teamInfo?: GraphCacheResolver<WithTypename<Admin>, AdminTeamInfoArgs, WithTypename<Service> | string>,
    teamRequestsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    teamsCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>,
    userInfo?: GraphCacheResolver<WithTypename<Admin>, AdminUserInfoArgs, WithTypename<OrganizationUser> | string>,
    usersCount?: GraphCacheResolver<WithTypename<Admin>, Record<string, never>, Scalars['Int'] | string>
  },
  AdminOrganization?: {
    orgID?: GraphCacheResolver<WithTypename<AdminOrganization>, Record<string, never>, Scalars['String'] | string>,
    orgName?: GraphCacheResolver<WithTypename<AdminOrganization>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<AdminOrganization>, Record<string, never>, Scalars['String'] | string>
  },
  InvitedUser?: {
    adminEmail?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['String'] | string>,
    adminUid?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['ID'] | string>,
    invitedOn?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['DateTime'] | string>,
    inviteeEmail?: GraphCacheResolver<WithTypename<InvitedUser>, Record<string, never>, Scalars['String'] | string>
  },
  ModuleReorderData?: {
    collection?: GraphCacheResolver<WithTypename<ModuleReorderData>, Record<string, never>, WithTypename<ServiceModule> | string>,
    nextCollection?: GraphCacheResolver<WithTypename<ModuleReorderData>, Record<string, never>, WithTypename<ServiceModule> | string>
  },
  Organization?: {
    createdBy?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['ID'] | string>,
    myProjects?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<Project> | string>>,
    myRole?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, OrganizationUserRole | string>,
    name?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>
  },
  OrganizationUser?: {
    authEmail?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, Scalars['String'] | string>,
    createdOn?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, Scalars['DateTime'] | string>,
    fullName?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, Scalars['ID'] | string>,
    photoURL?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, OrganizationUserRole | string>,
    userUid?: GraphCacheResolver<WithTypename<OrganizationUser>, Record<string, never>, Scalars['ID'] | string>
  },
  Project?: {
    adminUserUid?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['ID'] | string>,
    createdBy?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['ID'] | string>,
    isAdmin?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['ID'] | string>
  },
  ProjectOption?: {
    id?: GraphCacheResolver<WithTypename<ProjectOption>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<ProjectOption>, Record<string, never>, Scalars['String'] | string>
  },
  RequestReorderData?: {
    nextRequest?: GraphCacheResolver<WithTypename<RequestReorderData>, Record<string, never>, WithTypename<ServiceRequest> | string>,
    request?: GraphCacheResolver<WithTypename<RequestReorderData>, Record<string, never>, WithTypename<ServiceRequest> | string>
  },
  Service?: {
    editorsCount?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['ID'] | string>,
    members?: GraphCacheResolver<WithTypename<Service>, ServiceMembersArgs, Array<WithTypename<ServiceMember> | string>>,
    myRole?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, ServiceMemberRole | string>,
    name?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['ID'] | string>,
    ownersCount?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['Int'] | string>,
    projectID?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['ID'] | string>,
    serviceMembers?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Array<WithTypename<ServiceMember> | string>>,
    teamEnvironments?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Array<WithTypename<ServiceEnvironment> | string>>,
    viewersCount?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['Int'] | string>
  },
  ServiceDetailInfo?: {
    editorsCount?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['ID'] | string>,
    members?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Array<WithTypename<ServiceMemberInfo> | string>>,
    myRole?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, ServiceMemberRole | string>,
    name?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['ID'] | string>,
    ownersCount?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['Int'] | string>,
    projectID?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['ID'] | string>,
    projectName?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['String'] | string>,
    serviceMembers?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Array<WithTypename<ServiceMember> | string>>,
    teamEnvironments?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Array<WithTypename<ServiceEnvironment> | string>>,
    viewersCount?: GraphCacheResolver<WithTypename<ServiceDetailInfo>, Record<string, never>, Scalars['Int'] | string>
  },
  ServiceEnvironment?: {
    id?: GraphCacheResolver<WithTypename<ServiceEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<ServiceEnvironment>, Record<string, never>, Scalars['String'] | string>,
    serviceID?: GraphCacheResolver<WithTypename<ServiceEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    variables?: GraphCacheResolver<WithTypename<ServiceEnvironment>, Record<string, never>, Scalars['String'] | string>
  },
  ServiceInfo?: {
    editorsCount?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['ID'] | string>,
    members?: GraphCacheResolver<WithTypename<ServiceInfo>, ServiceInfoMembersArgs, Array<WithTypename<ServiceMember> | string>>,
    membersCount?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['Int'] | string>,
    myRole?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, ServiceMemberRole | string>,
    name?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['ID'] | string>,
    ownersCount?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['Int'] | string>,
    projectID?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['ID'] | string>,
    projectName?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['String'] | string>,
    serviceMembers?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Array<WithTypename<ServiceMember> | string>>,
    teamEnvironments?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Array<WithTypename<ServiceEnvironment> | string>>,
    viewersCount?: GraphCacheResolver<WithTypename<ServiceInfo>, Record<string, never>, Scalars['Int'] | string>
  },
  ServiceMember?: {
    membershipID?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, Scalars['ID'] | string>,
    orgID?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, Scalars['ID'] | string>,
    projectID?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, Scalars['ID'] | string>,
    role?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, ServiceMemberRole | string>,
    serviceID?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, Scalars['ID'] | string>,
    user?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, WithTypename<User> | string>,
    userUid?: GraphCacheResolver<WithTypename<ServiceMember>, Record<string, never>, Scalars['ID'] | string>
  },
  ServiceMemberInfo?: {
    email?: GraphCacheResolver<WithTypename<ServiceMemberInfo>, Record<string, never>, Scalars['String'] | string>,
    fullName?: GraphCacheResolver<WithTypename<ServiceMemberInfo>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<ServiceMemberInfo>, Record<string, never>, ServiceMemberRole | string>,
    userUid?: GraphCacheResolver<WithTypename<ServiceMemberInfo>, Record<string, never>, Scalars['ID'] | string>
  },
  ServiceModule?: {
    children?: GraphCacheResolver<WithTypename<ServiceModule>, ServiceModuleChildrenArgs, Array<WithTypename<ServiceModule> | string>>,
    id?: GraphCacheResolver<WithTypename<ServiceModule>, Record<string, never>, Scalars['ID'] | string>,
    parent?: GraphCacheResolver<WithTypename<ServiceModule>, Record<string, never>, WithTypename<ServiceModule> | string>,
    parentID?: GraphCacheResolver<WithTypename<ServiceModule>, Record<string, never>, Scalars['ID'] | string>,
    service?: GraphCacheResolver<WithTypename<ServiceModule>, Record<string, never>, WithTypename<Service> | string>,
    title?: GraphCacheResolver<WithTypename<ServiceModule>, Record<string, never>, Scalars['String'] | string>
  },
  ServiceRequest?: {
    id?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, Scalars['ID'] | string>,
    module?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, WithTypename<ServiceModule> | string>,
    moduleID?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, Scalars['String'] | string>,
    service?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, WithTypename<Service> | string>,
    serviceID?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, Scalars['ID'] | string>,
    title?: GraphCacheResolver<WithTypename<ServiceRequest>, Record<string, never>, Scalars['String'] | string>
  },
  Shortcode?: {
    createdOn?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<Shortcode>, Record<string, never>, Scalars['String'] | string>
  },
  User?: {
    GQLHistory?: GraphCacheResolver<WithTypename<User>, UserGqlHistoryArgs, Array<WithTypename<UserHistory> | string>>,
    RESTHistory?: GraphCacheResolver<WithTypename<User>, UserRestHistoryArgs, Array<WithTypename<UserHistory> | string>>,
    adminOrganizations?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<AdminOrganization> | string>>,
    createdOn?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['DateTime'] | string>,
    currentGQLSession?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    currentRESTSession?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    displayName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    environments?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<UserEnvironment> | string>>,
    globalEnvironments?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<UserEnvironment> | string>,
    photoURL?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    settings?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<UserSettings> | string>,
    uid?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['ID'] | string>
  },
  UserCollection?: {
    childrenGQL?: GraphCacheResolver<WithTypename<UserCollection>, UserCollectionChildrenGqlArgs, Array<WithTypename<UserCollection> | string>>,
    childrenREST?: GraphCacheResolver<WithTypename<UserCollection>, UserCollectionChildrenRestArgs, Array<WithTypename<UserCollection> | string>>,
    id?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, Scalars['ID'] | string>,
    parent?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, WithTypename<UserCollection> | string>,
    requests?: GraphCacheResolver<WithTypename<UserCollection>, UserCollectionRequestsArgs, Array<WithTypename<UserRequest> | string>>,
    title?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, ReqType | string>,
    user?: GraphCacheResolver<WithTypename<UserCollection>, Record<string, never>, WithTypename<User> | string>
  },
  UserCollectionExportJSONData?: {
    collectionType?: GraphCacheResolver<WithTypename<UserCollectionExportJsonData>, Record<string, never>, ReqType | string>,
    exportedCollection?: GraphCacheResolver<WithTypename<UserCollectionExportJsonData>, Record<string, never>, Scalars['ID'] | string>
  },
  UserCollectionRemovedData?: {
    id?: GraphCacheResolver<WithTypename<UserCollectionRemovedData>, Record<string, never>, Scalars['ID'] | string>,
    type?: GraphCacheResolver<WithTypename<UserCollectionRemovedData>, Record<string, never>, ReqType | string>
  },
  UserCollectionReorderData?: {
    nextUserCollection?: GraphCacheResolver<WithTypename<UserCollectionReorderData>, Record<string, never>, WithTypename<UserCollection> | string>,
    userCollection?: GraphCacheResolver<WithTypename<UserCollectionReorderData>, Record<string, never>, WithTypename<UserCollection> | string>
  },
  UserEnvironment?: {
    id?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    isGlobal?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['String'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['ID'] | string>,
    variables?: GraphCacheResolver<WithTypename<UserEnvironment>, Record<string, never>, Scalars['String'] | string>
  },
  UserGroup?: {
    createdAt?: GraphCacheResolver<WithTypename<UserGroup>, Record<string, never>, Scalars['DateTime'] | string>,
    createdBy?: GraphCacheResolver<WithTypename<UserGroup>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<UserGroup>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<UserGroup>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<UserGroup>, Record<string, never>, Scalars['ID'] | string>
  },
  UserGroupInfo?: {
    createdAt?: GraphCacheResolver<WithTypename<UserGroupInfo>, Record<string, never>, Scalars['DateTime'] | string>,
    createdBy?: GraphCacheResolver<WithTypename<UserGroupInfo>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<UserGroupInfo>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<UserGroupInfo>, Record<string, never>, Scalars['String'] | string>,
    orgID?: GraphCacheResolver<WithTypename<UserGroupInfo>, Record<string, never>, Scalars['ID'] | string>,
    userCount?: GraphCacheResolver<WithTypename<UserGroupInfo>, Record<string, never>, Scalars['Int'] | string>
  },
  UserGroupUserInfo?: {
    authEmail?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['DateTime'] | string>,
    createdBy?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['ID'] | string>,
    fullName?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['String'] | string>,
    groupID?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['ID'] | string>,
    orgID?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['ID'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserGroupUserInfo>, Record<string, never>, Scalars['ID'] | string>
  },
  UserHistory?: {
    executedOn?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['ID'] | string>,
    isStarred?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['Boolean'] | string>,
    reqType?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, ReqType | string>,
    request?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['String'] | string>,
    responseMetadata?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['String'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserHistory>, Record<string, never>, Scalars['ID'] | string>
  },
  UserHistoryDeletedManyData?: {
    count?: GraphCacheResolver<WithTypename<UserHistoryDeletedManyData>, Record<string, never>, Scalars['Int'] | string>,
    reqType?: GraphCacheResolver<WithTypename<UserHistoryDeletedManyData>, Record<string, never>, ReqType | string>
  },
  UserInfo?: {
    displayName?: GraphCacheResolver<WithTypename<UserInfo>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<UserInfo>, Record<string, never>, Scalars['String'] | string>,
    photoURL?: GraphCacheResolver<WithTypename<UserInfo>, Record<string, never>, Scalars['String'] | string>
  },
  UserRequest?: {
    collectionID?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['ID'] | string>,
    createdOn?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['ID'] | string>,
    request?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, ReqType | string>,
    user?: GraphCacheResolver<WithTypename<UserRequest>, Record<string, never>, WithTypename<User> | string>
  },
  UserRequestReorderData?: {
    nextRequest?: GraphCacheResolver<WithTypename<UserRequestReorderData>, Record<string, never>, WithTypename<UserRequest> | string>,
    request?: GraphCacheResolver<WithTypename<UserRequestReorderData>, Record<string, never>, WithTypename<UserRequest> | string>
  },
  UserSettings?: {
    id?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['ID'] | string>,
    properties?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['String'] | string>,
    updatedOn?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['DateTime'] | string>,
    userUid?: GraphCacheResolver<WithTypename<UserSettings>, Record<string, never>, Scalars['ID'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  addManyUsersToServiceViaEmail?: GraphCacheOptimisticMutationResolver<MutationAddManyUsersToServiceViaEmailArgs, Scalars['Boolean']>,
  addOneUserToServiceViaEmail?: GraphCacheOptimisticMutationResolver<MutationAddOneUserToServiceViaEmailArgs, WithTypename<ServiceMember>>,
  addUserToGroupByAdmin?: GraphCacheOptimisticMutationResolver<MutationAddUserToGroupByAdminArgs, WithTypename<UserGroupUserInfo>>,
  addUserToTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationAddUserToTeamByAdminArgs, WithTypename<ServiceMember>>,
  changeUserRoleInTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationChangeUserRoleInTeamByAdminArgs, WithTypename<ServiceMember>>,
  clearGlobalEnvironments?: GraphCacheOptimisticMutationResolver<MutationClearGlobalEnvironmentsArgs, WithTypename<UserEnvironment>>,
  createChildModule?: GraphCacheOptimisticMutationResolver<MutationCreateChildModuleArgs, WithTypename<ServiceModule>>,
  createDuplicateEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateDuplicateEnvironmentArgs, WithTypename<ServiceEnvironment>>,
  createGQLChildUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateGqlChildUserCollectionArgs, WithTypename<UserCollection>>,
  createGQLRootUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateGqlRootUserCollectionArgs, WithTypename<UserCollection>>,
  createGQLUserRequest?: GraphCacheOptimisticMutationResolver<MutationCreateGqlUserRequestArgs, WithTypename<UserRequest>>,
  createOrganizationUserByAdmin?: GraphCacheOptimisticMutationResolver<MutationCreateOrganizationUserByAdminArgs, WithTypename<OrganizationUser>>,
  createProject?: GraphCacheOptimisticMutationResolver<MutationCreateProjectArgs, WithTypename<Project>>,
  createRESTChildUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRestChildUserCollectionArgs, WithTypename<UserCollection>>,
  createRESTRootUserCollection?: GraphCacheOptimisticMutationResolver<MutationCreateRestRootUserCollectionArgs, WithTypename<UserCollection>>,
  createRESTUserRequest?: GraphCacheOptimisticMutationResolver<MutationCreateRestUserRequestArgs, WithTypename<UserRequest>>,
  createRequestInModule?: GraphCacheOptimisticMutationResolver<MutationCreateRequestInModuleArgs, WithTypename<ServiceRequest>>,
  createRootModule?: GraphCacheOptimisticMutationResolver<MutationCreateRootModuleArgs, WithTypename<ServiceModule>>,
  createService?: GraphCacheOptimisticMutationResolver<MutationCreateServiceArgs, WithTypename<Service>>,
  createServiceEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateServiceEnvironmentArgs, WithTypename<ServiceEnvironment>>,
  createShortcode?: GraphCacheOptimisticMutationResolver<MutationCreateShortcodeArgs, WithTypename<Shortcode>>,
  createTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationCreateTeamByAdminArgs, WithTypename<Service>>,
  createUserEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateUserEnvironmentArgs, WithTypename<UserEnvironment>>,
  createUserGlobalEnvironment?: GraphCacheOptimisticMutationResolver<MutationCreateUserGlobalEnvironmentArgs, WithTypename<UserEnvironment>>,
  createUserGroupByAdmin?: GraphCacheOptimisticMutationResolver<MutationCreateUserGroupByAdminArgs, WithTypename<UserGroup>>,
  createUserHistory?: GraphCacheOptimisticMutationResolver<MutationCreateUserHistoryArgs, WithTypename<UserHistory>>,
  createUserSettings?: GraphCacheOptimisticMutationResolver<MutationCreateUserSettingsArgs, WithTypename<UserSettings>>,
  deleteAllUserHistory?: GraphCacheOptimisticMutationResolver<MutationDeleteAllUserHistoryArgs, WithTypename<UserHistoryDeletedManyData>>,
  deleteAllVariablesFromTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteAllVariablesFromTeamEnvironmentArgs, WithTypename<ServiceEnvironment>>,
  deleteModule?: GraphCacheOptimisticMutationResolver<MutationDeleteModuleArgs, Scalars['Boolean']>,
  deleteProject?: GraphCacheOptimisticMutationResolver<MutationDeleteProjectArgs, Scalars['Boolean']>,
  deleteRequest?: GraphCacheOptimisticMutationResolver<MutationDeleteRequestArgs, Scalars['Boolean']>,
  deleteService?: GraphCacheOptimisticMutationResolver<MutationDeleteServiceArgs, Scalars['Boolean']>,
  deleteTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamByAdminArgs, Scalars['Boolean']>,
  deleteTeamEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteTeamEnvironmentArgs, Scalars['Boolean']>,
  deleteUser?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Boolean']>,
  deleteUserCollection?: GraphCacheOptimisticMutationResolver<MutationDeleteUserCollectionArgs, Scalars['Boolean']>,
  deleteUserEnvironment?: GraphCacheOptimisticMutationResolver<MutationDeleteUserEnvironmentArgs, Scalars['Boolean']>,
  deleteUserEnvironments?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['Int']>,
  deleteUserGroupByAdmin?: GraphCacheOptimisticMutationResolver<MutationDeleteUserGroupByAdminArgs, Scalars['Boolean']>,
  deleteUserRequest?: GraphCacheOptimisticMutationResolver<MutationDeleteUserRequestArgs, Scalars['Boolean']>,
  importModulesFromJSON?: GraphCacheOptimisticMutationResolver<MutationImportModulesFromJsonArgs, Scalars['Boolean']>,
  importUserCollectionsFromJSON?: GraphCacheOptimisticMutationResolver<MutationImportUserCollectionsFromJsonArgs, Scalars['Boolean']>,
  inviteNewUser?: GraphCacheOptimisticMutationResolver<MutationInviteNewUserArgs, WithTypename<InvitedUser>>,
  leaveService?: GraphCacheOptimisticMutationResolver<MutationLeaveServiceArgs, Scalars['Boolean']>,
  makeUserAdmin?: GraphCacheOptimisticMutationResolver<MutationMakeUserAdminArgs, Scalars['Boolean']>,
  moveModule?: GraphCacheOptimisticMutationResolver<MutationMoveModuleArgs, WithTypename<ServiceModule>>,
  moveRequest?: GraphCacheOptimisticMutationResolver<MutationMoveRequestArgs, WithTypename<ServiceRequest>>,
  moveUserCollection?: GraphCacheOptimisticMutationResolver<MutationMoveUserCollectionArgs, WithTypename<UserCollection>>,
  moveUserRequest?: GraphCacheOptimisticMutationResolver<MutationMoveUserRequestArgs, WithTypename<UserRequest>>,
  removeRequestFromHistory?: GraphCacheOptimisticMutationResolver<MutationRemoveRequestFromHistoryArgs, WithTypename<UserHistory>>,
  removeServiceMember?: GraphCacheOptimisticMutationResolver<MutationRemoveServiceMemberArgs, Scalars['Boolean']>,
  removeUserAsAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserAsAdminArgs, Scalars['Boolean']>,
  removeUserByAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserByAdminArgs, Scalars['Boolean']>,
  removeUserFromGroupByAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserFromGroupByAdminArgs, Scalars['Boolean']>,
  removeUserFromTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationRemoveUserFromTeamByAdminArgs, Scalars['Boolean']>,
  renameModule?: GraphCacheOptimisticMutationResolver<MutationRenameModuleArgs, WithTypename<ServiceModule>>,
  renameProject?: GraphCacheOptimisticMutationResolver<MutationRenameProjectArgs, WithTypename<Project>>,
  renameService?: GraphCacheOptimisticMutationResolver<MutationRenameServiceArgs, WithTypename<Service>>,
  renameTeamByAdmin?: GraphCacheOptimisticMutationResolver<MutationRenameTeamByAdminArgs, WithTypename<Service>>,
  renameUserCollection?: GraphCacheOptimisticMutationResolver<MutationRenameUserCollectionArgs, WithTypename<UserCollection>>,
  renameUserGroupByAdmin?: GraphCacheOptimisticMutationResolver<MutationRenameUserGroupByAdminArgs, Scalars['Boolean']>,
  replaceCollectionsWithJSON?: GraphCacheOptimisticMutationResolver<MutationReplaceCollectionsWithJsonArgs, Scalars['Boolean']>,
  revokeShortcode?: GraphCacheOptimisticMutationResolver<MutationRevokeShortcodeArgs, Scalars['Boolean']>,
  toggleHistoryStarStatus?: GraphCacheOptimisticMutationResolver<MutationToggleHistoryStarStatusArgs, WithTypename<UserHistory>>,
  updateGQLUserRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateGqlUserRequestArgs, WithTypename<UserRequest>>,
  updateLookUpRequestOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateLookUpRequestOrderArgs, Scalars['Boolean']>,
  updateModuleOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateModuleOrderArgs, Scalars['Boolean']>,
  updateOrganizationUserByAdmin?: GraphCacheOptimisticMutationResolver<MutationUpdateOrganizationUserByAdminArgs, WithTypename<OrganizationUser>>,
  updateRESTUserRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateRestUserRequestArgs, WithTypename<UserRequest>>,
  updateRequest?: GraphCacheOptimisticMutationResolver<MutationUpdateRequestArgs, WithTypename<ServiceRequest>>,
  updateServiceEnvironment?: GraphCacheOptimisticMutationResolver<MutationUpdateServiceEnvironmentArgs, WithTypename<ServiceEnvironment>>,
  updateServiceMemberRole?: GraphCacheOptimisticMutationResolver<MutationUpdateServiceMemberRoleArgs, WithTypename<ServiceMember>>,
  updateUserCollectionOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateUserCollectionOrderArgs, Scalars['Boolean']>,
  updateUserEnvironment?: GraphCacheOptimisticMutationResolver<MutationUpdateUserEnvironmentArgs, WithTypename<UserEnvironment>>,
  updateUserSessions?: GraphCacheOptimisticMutationResolver<MutationUpdateUserSessionsArgs, WithTypename<User>>,
  updateUserSettings?: GraphCacheOptimisticMutationResolver<MutationUpdateUserSettingsArgs, WithTypename<UserSettings>>
};

export type GraphCacheUpdaters = {
  Mutation?: {
    addManyUsersToServiceViaEmail?: GraphCacheUpdateResolver<{ addManyUsersToServiceViaEmail: Scalars['Boolean'] }, MutationAddManyUsersToServiceViaEmailArgs>,
    addOneUserToServiceViaEmail?: GraphCacheUpdateResolver<{ addOneUserToServiceViaEmail: WithTypename<ServiceMember> }, MutationAddOneUserToServiceViaEmailArgs>,
    addUserToGroupByAdmin?: GraphCacheUpdateResolver<{ addUserToGroupByAdmin: WithTypename<UserGroupUserInfo> }, MutationAddUserToGroupByAdminArgs>,
    addUserToTeamByAdmin?: GraphCacheUpdateResolver<{ addUserToTeamByAdmin: WithTypename<ServiceMember> }, MutationAddUserToTeamByAdminArgs>,
    changeUserRoleInTeamByAdmin?: GraphCacheUpdateResolver<{ changeUserRoleInTeamByAdmin: WithTypename<ServiceMember> }, MutationChangeUserRoleInTeamByAdminArgs>,
    clearGlobalEnvironments?: GraphCacheUpdateResolver<{ clearGlobalEnvironments: WithTypename<UserEnvironment> }, MutationClearGlobalEnvironmentsArgs>,
    createChildModule?: GraphCacheUpdateResolver<{ createChildModule: WithTypename<ServiceModule> }, MutationCreateChildModuleArgs>,
    createDuplicateEnvironment?: GraphCacheUpdateResolver<{ createDuplicateEnvironment: WithTypename<ServiceEnvironment> }, MutationCreateDuplicateEnvironmentArgs>,
    createGQLChildUserCollection?: GraphCacheUpdateResolver<{ createGQLChildUserCollection: WithTypename<UserCollection> }, MutationCreateGqlChildUserCollectionArgs>,
    createGQLRootUserCollection?: GraphCacheUpdateResolver<{ createGQLRootUserCollection: WithTypename<UserCollection> }, MutationCreateGqlRootUserCollectionArgs>,
    createGQLUserRequest?: GraphCacheUpdateResolver<{ createGQLUserRequest: WithTypename<UserRequest> }, MutationCreateGqlUserRequestArgs>,
    createOrganizationUserByAdmin?: GraphCacheUpdateResolver<{ createOrganizationUserByAdmin: WithTypename<OrganizationUser> }, MutationCreateOrganizationUserByAdminArgs>,
    createProject?: GraphCacheUpdateResolver<{ createProject: WithTypename<Project> }, MutationCreateProjectArgs>,
    createRESTChildUserCollection?: GraphCacheUpdateResolver<{ createRESTChildUserCollection: WithTypename<UserCollection> }, MutationCreateRestChildUserCollectionArgs>,
    createRESTRootUserCollection?: GraphCacheUpdateResolver<{ createRESTRootUserCollection: WithTypename<UserCollection> }, MutationCreateRestRootUserCollectionArgs>,
    createRESTUserRequest?: GraphCacheUpdateResolver<{ createRESTUserRequest: WithTypename<UserRequest> }, MutationCreateRestUserRequestArgs>,
    createRequestInModule?: GraphCacheUpdateResolver<{ createRequestInModule: WithTypename<ServiceRequest> }, MutationCreateRequestInModuleArgs>,
    createRootModule?: GraphCacheUpdateResolver<{ createRootModule: WithTypename<ServiceModule> }, MutationCreateRootModuleArgs>,
    createService?: GraphCacheUpdateResolver<{ createService: WithTypename<Service> }, MutationCreateServiceArgs>,
    createServiceEnvironment?: GraphCacheUpdateResolver<{ createServiceEnvironment: WithTypename<ServiceEnvironment> }, MutationCreateServiceEnvironmentArgs>,
    createShortcode?: GraphCacheUpdateResolver<{ createShortcode: WithTypename<Shortcode> }, MutationCreateShortcodeArgs>,
    createTeamByAdmin?: GraphCacheUpdateResolver<{ createTeamByAdmin: WithTypename<Service> }, MutationCreateTeamByAdminArgs>,
    createUserEnvironment?: GraphCacheUpdateResolver<{ createUserEnvironment: WithTypename<UserEnvironment> }, MutationCreateUserEnvironmentArgs>,
    createUserGlobalEnvironment?: GraphCacheUpdateResolver<{ createUserGlobalEnvironment: WithTypename<UserEnvironment> }, MutationCreateUserGlobalEnvironmentArgs>,
    createUserGroupByAdmin?: GraphCacheUpdateResolver<{ createUserGroupByAdmin: WithTypename<UserGroup> }, MutationCreateUserGroupByAdminArgs>,
    createUserHistory?: GraphCacheUpdateResolver<{ createUserHistory: WithTypename<UserHistory> }, MutationCreateUserHistoryArgs>,
    createUserSettings?: GraphCacheUpdateResolver<{ createUserSettings: WithTypename<UserSettings> }, MutationCreateUserSettingsArgs>,
    deleteAllUserHistory?: GraphCacheUpdateResolver<{ deleteAllUserHistory: WithTypename<UserHistoryDeletedManyData> }, MutationDeleteAllUserHistoryArgs>,
    deleteAllVariablesFromTeamEnvironment?: GraphCacheUpdateResolver<{ deleteAllVariablesFromTeamEnvironment: WithTypename<ServiceEnvironment> }, MutationDeleteAllVariablesFromTeamEnvironmentArgs>,
    deleteModule?: GraphCacheUpdateResolver<{ deleteModule: Scalars['Boolean'] }, MutationDeleteModuleArgs>,
    deleteProject?: GraphCacheUpdateResolver<{ deleteProject: Scalars['Boolean'] }, MutationDeleteProjectArgs>,
    deleteRequest?: GraphCacheUpdateResolver<{ deleteRequest: Scalars['Boolean'] }, MutationDeleteRequestArgs>,
    deleteService?: GraphCacheUpdateResolver<{ deleteService: Scalars['Boolean'] }, MutationDeleteServiceArgs>,
    deleteTeamByAdmin?: GraphCacheUpdateResolver<{ deleteTeamByAdmin: Scalars['Boolean'] }, MutationDeleteTeamByAdminArgs>,
    deleteTeamEnvironment?: GraphCacheUpdateResolver<{ deleteTeamEnvironment: Scalars['Boolean'] }, MutationDeleteTeamEnvironmentArgs>,
    deleteUser?: GraphCacheUpdateResolver<{ deleteUser: Scalars['Boolean'] }, Record<string, never>>,
    deleteUserCollection?: GraphCacheUpdateResolver<{ deleteUserCollection: Scalars['Boolean'] }, MutationDeleteUserCollectionArgs>,
    deleteUserEnvironment?: GraphCacheUpdateResolver<{ deleteUserEnvironment: Scalars['Boolean'] }, MutationDeleteUserEnvironmentArgs>,
    deleteUserEnvironments?: GraphCacheUpdateResolver<{ deleteUserEnvironments: Scalars['Int'] }, Record<string, never>>,
    deleteUserGroupByAdmin?: GraphCacheUpdateResolver<{ deleteUserGroupByAdmin: Scalars['Boolean'] }, MutationDeleteUserGroupByAdminArgs>,
    deleteUserRequest?: GraphCacheUpdateResolver<{ deleteUserRequest: Scalars['Boolean'] }, MutationDeleteUserRequestArgs>,
    importModulesFromJSON?: GraphCacheUpdateResolver<{ importModulesFromJSON: Scalars['Boolean'] }, MutationImportModulesFromJsonArgs>,
    importUserCollectionsFromJSON?: GraphCacheUpdateResolver<{ importUserCollectionsFromJSON: Scalars['Boolean'] }, MutationImportUserCollectionsFromJsonArgs>,
    inviteNewUser?: GraphCacheUpdateResolver<{ inviteNewUser: WithTypename<InvitedUser> }, MutationInviteNewUserArgs>,
    leaveService?: GraphCacheUpdateResolver<{ leaveService: Scalars['Boolean'] }, MutationLeaveServiceArgs>,
    makeUserAdmin?: GraphCacheUpdateResolver<{ makeUserAdmin: Scalars['Boolean'] }, MutationMakeUserAdminArgs>,
    moveModule?: GraphCacheUpdateResolver<{ moveModule: WithTypename<ServiceModule> }, MutationMoveModuleArgs>,
    moveRequest?: GraphCacheUpdateResolver<{ moveRequest: WithTypename<ServiceRequest> }, MutationMoveRequestArgs>,
    moveUserCollection?: GraphCacheUpdateResolver<{ moveUserCollection: WithTypename<UserCollection> }, MutationMoveUserCollectionArgs>,
    moveUserRequest?: GraphCacheUpdateResolver<{ moveUserRequest: WithTypename<UserRequest> }, MutationMoveUserRequestArgs>,
    removeRequestFromHistory?: GraphCacheUpdateResolver<{ removeRequestFromHistory: WithTypename<UserHistory> }, MutationRemoveRequestFromHistoryArgs>,
    removeServiceMember?: GraphCacheUpdateResolver<{ removeServiceMember: Scalars['Boolean'] }, MutationRemoveServiceMemberArgs>,
    removeUserAsAdmin?: GraphCacheUpdateResolver<{ removeUserAsAdmin: Scalars['Boolean'] }, MutationRemoveUserAsAdminArgs>,
    removeUserByAdmin?: GraphCacheUpdateResolver<{ removeUserByAdmin: Scalars['Boolean'] }, MutationRemoveUserByAdminArgs>,
    removeUserFromGroupByAdmin?: GraphCacheUpdateResolver<{ removeUserFromGroupByAdmin: Scalars['Boolean'] }, MutationRemoveUserFromGroupByAdminArgs>,
    removeUserFromTeamByAdmin?: GraphCacheUpdateResolver<{ removeUserFromTeamByAdmin: Scalars['Boolean'] }, MutationRemoveUserFromTeamByAdminArgs>,
    renameModule?: GraphCacheUpdateResolver<{ renameModule: WithTypename<ServiceModule> }, MutationRenameModuleArgs>,
    renameProject?: GraphCacheUpdateResolver<{ renameProject: WithTypename<Project> }, MutationRenameProjectArgs>,
    renameService?: GraphCacheUpdateResolver<{ renameService: WithTypename<Service> }, MutationRenameServiceArgs>,
    renameTeamByAdmin?: GraphCacheUpdateResolver<{ renameTeamByAdmin: WithTypename<Service> }, MutationRenameTeamByAdminArgs>,
    renameUserCollection?: GraphCacheUpdateResolver<{ renameUserCollection: WithTypename<UserCollection> }, MutationRenameUserCollectionArgs>,
    renameUserGroupByAdmin?: GraphCacheUpdateResolver<{ renameUserGroupByAdmin: Scalars['Boolean'] }, MutationRenameUserGroupByAdminArgs>,
    replaceCollectionsWithJSON?: GraphCacheUpdateResolver<{ replaceCollectionsWithJSON: Scalars['Boolean'] }, MutationReplaceCollectionsWithJsonArgs>,
    revokeShortcode?: GraphCacheUpdateResolver<{ revokeShortcode: Scalars['Boolean'] }, MutationRevokeShortcodeArgs>,
    toggleHistoryStarStatus?: GraphCacheUpdateResolver<{ toggleHistoryStarStatus: WithTypename<UserHistory> }, MutationToggleHistoryStarStatusArgs>,
    updateGQLUserRequest?: GraphCacheUpdateResolver<{ updateGQLUserRequest: WithTypename<UserRequest> }, MutationUpdateGqlUserRequestArgs>,
    updateLookUpRequestOrder?: GraphCacheUpdateResolver<{ updateLookUpRequestOrder: Scalars['Boolean'] }, MutationUpdateLookUpRequestOrderArgs>,
    updateModuleOrder?: GraphCacheUpdateResolver<{ updateModuleOrder: Scalars['Boolean'] }, MutationUpdateModuleOrderArgs>,
    updateOrganizationUserByAdmin?: GraphCacheUpdateResolver<{ updateOrganizationUserByAdmin: WithTypename<OrganizationUser> }, MutationUpdateOrganizationUserByAdminArgs>,
    updateRESTUserRequest?: GraphCacheUpdateResolver<{ updateRESTUserRequest: WithTypename<UserRequest> }, MutationUpdateRestUserRequestArgs>,
    updateRequest?: GraphCacheUpdateResolver<{ updateRequest: WithTypename<ServiceRequest> }, MutationUpdateRequestArgs>,
    updateServiceEnvironment?: GraphCacheUpdateResolver<{ updateServiceEnvironment: WithTypename<ServiceEnvironment> }, MutationUpdateServiceEnvironmentArgs>,
    updateServiceMemberRole?: GraphCacheUpdateResolver<{ updateServiceMemberRole: WithTypename<ServiceMember> }, MutationUpdateServiceMemberRoleArgs>,
    updateUserCollectionOrder?: GraphCacheUpdateResolver<{ updateUserCollectionOrder: Scalars['Boolean'] }, MutationUpdateUserCollectionOrderArgs>,
    updateUserEnvironment?: GraphCacheUpdateResolver<{ updateUserEnvironment: WithTypename<UserEnvironment> }, MutationUpdateUserEnvironmentArgs>,
    updateUserSessions?: GraphCacheUpdateResolver<{ updateUserSessions: WithTypename<User> }, MutationUpdateUserSessionsArgs>,
    updateUserSettings?: GraphCacheUpdateResolver<{ updateUserSettings: WithTypename<UserSettings> }, MutationUpdateUserSettingsArgs>
  },
  Subscription?: {
    moduleOrderUpdated?: GraphCacheUpdateResolver<{ moduleOrderUpdated: WithTypename<ModuleReorderData> }, SubscriptionModuleOrderUpdatedArgs>,
    myShortcodesCreated?: GraphCacheUpdateResolver<{ myShortcodesCreated: WithTypename<Shortcode> }, Record<string, never>>,
    myShortcodesRevoked?: GraphCacheUpdateResolver<{ myShortcodesRevoked: WithTypename<Shortcode> }, Record<string, never>>,
    requestMoved?: GraphCacheUpdateResolver<{ requestMoved: WithTypename<ServiceRequest> }, SubscriptionRequestMovedArgs>,
    requestOrderUpdated?: GraphCacheUpdateResolver<{ requestOrderUpdated: WithTypename<RequestReorderData> }, SubscriptionRequestOrderUpdatedArgs>,
    serviceEnvironmentCreated?: GraphCacheUpdateResolver<{ serviceEnvironmentCreated: WithTypename<ServiceEnvironment> }, SubscriptionServiceEnvironmentCreatedArgs>,
    serviceEnvironmentDeleted?: GraphCacheUpdateResolver<{ serviceEnvironmentDeleted: WithTypename<ServiceEnvironment> }, SubscriptionServiceEnvironmentDeletedArgs>,
    serviceEnvironmentUpdated?: GraphCacheUpdateResolver<{ serviceEnvironmentUpdated: WithTypename<ServiceEnvironment> }, SubscriptionServiceEnvironmentUpdatedArgs>,
    serviceMemberAdded?: GraphCacheUpdateResolver<{ serviceMemberAdded: WithTypename<ServiceMember> }, SubscriptionServiceMemberAddedArgs>,
    serviceMemberRemoved?: GraphCacheUpdateResolver<{ serviceMemberRemoved: Scalars['ID'] }, SubscriptionServiceMemberRemovedArgs>,
    serviceMemberUpdated?: GraphCacheUpdateResolver<{ serviceMemberUpdated: WithTypename<ServiceMember> }, SubscriptionServiceMemberUpdatedArgs>,
    serviceModuleAdded?: GraphCacheUpdateResolver<{ serviceModuleAdded: WithTypename<ServiceModule> }, SubscriptionServiceModuleAddedArgs>,
    serviceModuleMoved?: GraphCacheUpdateResolver<{ serviceModuleMoved: WithTypename<ServiceModule> }, SubscriptionServiceModuleMovedArgs>,
    serviceModuleRemoved?: GraphCacheUpdateResolver<{ serviceModuleRemoved: Scalars['ID'] }, SubscriptionServiceModuleRemovedArgs>,
    serviceModuleUpdated?: GraphCacheUpdateResolver<{ serviceModuleUpdated: WithTypename<ServiceModule> }, SubscriptionServiceModuleUpdatedArgs>,
    serviceRequestAdded?: GraphCacheUpdateResolver<{ serviceRequestAdded: WithTypename<ServiceRequest> }, SubscriptionServiceRequestAddedArgs>,
    serviceRequestDeleted?: GraphCacheUpdateResolver<{ serviceRequestDeleted: Scalars['ID'] }, SubscriptionServiceRequestDeletedArgs>,
    serviceRequestUpdated?: GraphCacheUpdateResolver<{ serviceRequestUpdated: WithTypename<ServiceRequest> }, SubscriptionServiceRequestUpdatedArgs>,
    userCollectionCreated?: GraphCacheUpdateResolver<{ userCollectionCreated: WithTypename<UserCollection> }, Record<string, never>>,
    userCollectionMoved?: GraphCacheUpdateResolver<{ userCollectionMoved: WithTypename<UserCollection> }, Record<string, never>>,
    userCollectionOrderUpdated?: GraphCacheUpdateResolver<{ userCollectionOrderUpdated: WithTypename<UserCollectionReorderData> }, Record<string, never>>,
    userCollectionRemoved?: GraphCacheUpdateResolver<{ userCollectionRemoved: WithTypename<UserCollectionRemovedData> }, Record<string, never>>,
    userCollectionUpdated?: GraphCacheUpdateResolver<{ userCollectionUpdated: WithTypename<UserCollection> }, Record<string, never>>,
    userDeleted?: GraphCacheUpdateResolver<{ userDeleted: WithTypename<User> }, Record<string, never>>,
    userEnvironmentCreated?: GraphCacheUpdateResolver<{ userEnvironmentCreated: WithTypename<UserEnvironment> }, Record<string, never>>,
    userEnvironmentDeleteMany?: GraphCacheUpdateResolver<{ userEnvironmentDeleteMany: Scalars['Int'] }, Record<string, never>>,
    userEnvironmentDeleted?: GraphCacheUpdateResolver<{ userEnvironmentDeleted: WithTypename<UserEnvironment> }, Record<string, never>>,
    userEnvironmentUpdated?: GraphCacheUpdateResolver<{ userEnvironmentUpdated: WithTypename<UserEnvironment> }, Record<string, never>>,
    userHistoryCreated?: GraphCacheUpdateResolver<{ userHistoryCreated: WithTypename<UserHistory> }, Record<string, never>>,
    userHistoryDeleted?: GraphCacheUpdateResolver<{ userHistoryDeleted: WithTypename<UserHistory> }, Record<string, never>>,
    userHistoryDeletedMany?: GraphCacheUpdateResolver<{ userHistoryDeletedMany: WithTypename<UserHistoryDeletedManyData> }, Record<string, never>>,
    userHistoryUpdated?: GraphCacheUpdateResolver<{ userHistoryUpdated: WithTypename<UserHistory> }, Record<string, never>>,
    userInvited?: GraphCacheUpdateResolver<{ userInvited: WithTypename<InvitedUser> }, Record<string, never>>,
    userRequestCreated?: GraphCacheUpdateResolver<{ userRequestCreated: WithTypename<UserRequest> }, Record<string, never>>,
    userRequestDeleted?: GraphCacheUpdateResolver<{ userRequestDeleted: WithTypename<UserRequest> }, Record<string, never>>,
    userRequestMoved?: GraphCacheUpdateResolver<{ userRequestMoved: WithTypename<UserRequestReorderData> }, Record<string, never>>,
    userRequestUpdated?: GraphCacheUpdateResolver<{ userRequestUpdated: WithTypename<UserRequest> }, Record<string, never>>,
    userSettingsCreated?: GraphCacheUpdateResolver<{ userSettingsCreated: WithTypename<UserSettings> }, Record<string, never>>,
    userSettingsUpdated?: GraphCacheUpdateResolver<{ userSettingsUpdated: WithTypename<UserSettings> }, Record<string, never>>,
    userUpdated?: GraphCacheUpdateResolver<{ userUpdated: WithTypename<User> }, Record<string, never>>
  },
};

export type GraphCacheConfig = {
  schema?: IntrospectionData,
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
  storage?: GraphCacheStorageAdapter
};
<template>
  <HoppSmartModal
    v-if="show"
    dialog
    :title="t('project.titleCreate')"
    @close="hideModal"
  >
    <template #body>
      <div class="flex flex-col">
        <input
          id="selectLabelTeamAdd"
          v-model="name"
          v-focus
          class="input floating-input"
          placeholder=" "
          type="text"
          autocomplete="off"
          @keyup.enter="addNewTeam"
        />
        <label for="selectLabelTeamAdd">
          {{ t("action.label") }}
        </label>
      </div>
    </template>
    <template #footer>
      <span class="flex space-x-2">
        <HoppButtonPrimary
          :label="t('action.create')"
          :loading="isLoading"
          outline
          @click="addNewTeam"
        />
        <HoppButtonSecondary
          :label="t('action.cancel')"
          outline
          filled
          @click="hideModal"
        />
      </span>
    </template>
  </HoppSmartModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { pipe } from "fp-ts/function"
import * as TE from "fp-ts/TaskEither"
import { createProject } from "~/helpers/backend/mutations/Project"
import {
  ProjectNameCodec,
  ProjectName,
} from "~/helpers/backend/types/ProjectName"
import { useI18n } from "@composables/i18n"
import { useToast } from "@composables/toast"
import ProjectListAdapter from "~/helpers/projects/ProjectListAdapter"

const t = useI18n()

const toast = useToast()

defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: "hide-modal"): void
}>()

const name = ref<string | null>(null)
const orgId = ref<string>("ttlab")

const isLoading = ref(false)

const projectListAdapter = new ProjectListAdapter(true)

const addNewTeam = async () => {
  isLoading.value = true
  await pipe(
    ProjectNameCodec.decode(name.value),
    TE.fromEither,
    TE.mapLeft(() => "invalid_name" as const),
    TE.chainW(() => {
      return createProject(name.value as ProjectName, orgId.value)
    }),
    TE.match(
      (err) => {
        // err is of type "invalid_name" | GQLError<Err>
        if (err === "invalid_name") {
          toast.error(`${t("project.createFailed")}`)
        } else {
          // Handle GQL errors (use err obj)
        }
      },
      () => {
        toast.success(`${t("project.createSuccess")}`)
        hideModal()
        projectListAdapter.fetchList()
      }
    )
  )()
  isLoading.value = false
}

const hideModal = () => {
  name.value = null
  emit("hide-modal")
}
</script>

import * as E from "fp-ts/Either"
import { BehaviorSubject } from "rxjs"
import { GQLError, runGQLQuery } from "../backend/GQLClient"
import {
  GetMyProjectServicesDocument,
  GetMyProjectServicesQuery,
} from "../backend/graphql"
import { platform } from "~/platform"

// const BACKEND_PAGE_SIZE = 10
// const POLL_DURATION = 10000

export default class TeamListAdapter {
  static _instance: TeamListAdapter
  error$: BehaviorSubject<GQLError<string> | null>
  loading$: BehaviorSubject<boolean>
  teamList$: BehaviorSubject<GetMyProjectServicesQuery["myProjectServices"]>

  private timeoutHandle: ReturnType<typeof setTimeout> | null
  private isDispose: boolean

  public isInitialized: boolean

  constructor(private projectID: string | null) {
    const _instance = TeamListAdapter._instance
    if (_instance) {
      this.error$ = _instance.error$
      this.loading$ = _instance.loading$
      this.teamList$ = _instance.teamList$
      this.timeoutHandle = _instance.timeoutHandle
      this.isDispose = _instance.isDispose

      this.isInitialized = _instance.isInitialized
      return TeamListAdapter._instance
    }
    this.error$ = new BehaviorSubject<GQLError<string> | null>(null)
    this.loading$ = new BehaviorSubject<boolean>(false)
    this.teamList$ = new BehaviorSubject<
      GetMyProjectServicesQuery["myProjectServices"]
    >([])
    this.timeoutHandle = null
    this.isDispose = false

    this.isInitialized = false
    if (this.projectID) this.initialize()
    TeamListAdapter._instance = this
  }

  changeProjectID(newProjectID: string | null) {
    this.projectID = newProjectID
    this.teamList$.next([])
    if (this.projectID) this.fetchList()
  }

  initialize() {
    if (this.timeoutHandle) throw new Error(`Adapter already initialized`)
    if (this.isDispose) throw new Error(`Adapter has been disposed`)
    if (!this.isInitialized) {
      this.fetchList()
    }
    this.isInitialized = true
  }

  public dispose() {
    this.isDispose = true
    clearTimeout(this.timeoutHandle as any)
    this.timeoutHandle = null
    this.isInitialized = false
  }

  async fetchList() {
    if (!this.projectID) return //throw new Error("Project ID is null")
    const currentUser = platform.auth.getCurrentUser()

    // if the authIdToken is not present, don't fetch the teams list, as it will fail anyway
    if (!currentUser) return

    this.loading$.next(true)

    const results: GetMyProjectServicesQuery["myProjectServices"] = []

    const result = await runGQLQuery({
      query: GetMyProjectServicesDocument,
      variables: {
        projectID: this.projectID,
      },
    })

    if (E.isLeft(result)) {
      this.error$.next(result.left)
      throw new Error(
        `Failed fetching teams list: ${JSON.stringify(result.left)}`
      )
    }

    results.push(...result.right.myProjectServices)

    // while (true) {
    //   const result = await runGQLQuery({
    //     query: GetMyProjectServicesDocument,
    //     variables: {
    //       projectID: projectID,
    //     },
    //   })

    //   if (E.isLeft(result)) {
    //     this.error$.next(result.left)
    //     throw new Error(
    //       `Failed fetching teams list: ${JSON.stringify(result.left)}`
    //     )
    //   }

    //   results.push(...result.right.myProjectServices)

    //   // If we don't have full elements in the list, then the list is done usually, so lets stop
    //   if (result.right.myProjectServices.length !== BACKEND_PAGE_SIZE) break
    // }

    this.teamList$.next(results)

    this.loading$.next(false)
  }
}
